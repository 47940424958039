@import "../../common.scss";

.inner_sec1 {
  margin: 8.25em 0 0;
  padding: 6.563em 0 4.688em;
  .text_container {
    .breadcrumb {
      color: $color-E8C339;
      font-size: 1.125em;
      letter-spacing: 0.9px;
      line-height: 1.667em;
      margin: 0 0 2.111em;
      a {
        color: $color-E8C339;
      }
    }
  }
  .news_body {
    .subheading {
      margin: 0 0 0.8em;
      max-width: 75%;
    }
    .desc_wrap {
      font-size: 1.25em;
      line-height: 1.9em;
      color: $color-1B1B1B;
      p {
        padding: 0 0 2em;
        margin: 0;
      }
    }
    .hero_img {
      padding: 0 0 2.5em;
      margin: 0;
      width: 100%;
    }
  }
}

.inner_sec2 {
  padding: 0 0 7.188em;
  margin: 0;
  .head_arrow_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrows_wrapper {
      display: flex;
      align-items: center;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 4.938em;
        height: 4.938em;
        cursor: pointer;
      }
      .left_arrow {
        margin-right: 1.75em;
      }
    }
  }
  .img_wrapper {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin: 3.25em 0 0;
    z-index: 5;
    border-radius: 7.5px;
  }
  .gallery_img {
    width: 100%;
    transform: scale(1);
    transition: 0.5s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media (max-width: 1024px) {
  .inner_sec1 {
    margin: 9.5em 0 0;
  }
}

@media (max-width: 767px) {
  .inner_sec1 {
    margin: 8em 0 0;
    .news_body {
      .subheading {
        max-width: 100%;
      }
    }
  }
}
