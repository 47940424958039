@import "../../common.scss";

.product_sec2 {
  padding: 6.188em 0 4.188em;
  text-align: center;
  .subheading {
    width: 100%;
    max-width: 18.76em;
    margin: 0 auto;
  }
}

.product_sec3 {
  padding-bottom: 5.75em;
  margin-bottom: 5em;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    height: 0.5px;
    background-color: #242424;
    width: 100%;
    // max-width: 107.741em;
    max-width: 91.19%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.5;
  }
  .product_slider_row {
    margin: 0 auto;
    position: relative;
    .product_slider_col {
      width: 100%;
      max-width: 33.33%;
      flex: 0 0 33.33%;
      padding: 0;
      text-align: center;
      white-space: nowrap;
      .tab_heading {
        font-size: 1.5em;
        font-weight: 600;
        line-height: 2.188rem;
        color: $color-1B1B1B;
      }
    }
    .product_list {
      display: flex;
      flex: 0 0 30%;
      flex-wrap: noWrap;
      justify-content: space-between;
      // overflow-x: auto;
      overflow: hidden;
      width: 100%;
      max-width: 90%;
      // max-width: 100%;
      scroll-behavior: smooth;
      margin: 0 auto;
    }
    .product_arrow {
      .product_left_arrow,
      .product_right_arrow {
        position: absolute;
        background-color: #ffffff;
        width: 100%;
        max-width: 4.938em;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .product_left_arrow {
        left: -2.5em;
      }
      .product_right_arrow {
        right: -2.5em;
      }
    }
  }

  //   tab section start
  .left_section {
    text-align: center;
    ul {
      padding: 0;
      display: flex;
      justify-content: flex-start;
    }
    .lefttab {
      position: relative;
      font-size: 1.5em;
      // line-height: 4.375rem;
      line-height: 2.188rem;
      letter-spacing: 0;
      color: $color-000000;
      font-weight: 600;
      opacity: 0.4;
      cursor: pointer;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      // transition: 0.3s all ease-in-out;
      // margin: 0 3.71em 1.875em 0;
      // border-bottom: 0px solid $color-E8C339;

      // span {
      //   width: 100%;
      //   max-width: 60%;
      // }
    }
    .lefttab.active {
      color: $color-1B1B1B;
      background-size: contain;
      font-weight: 600;
      position: relative;
      opacity: 1;
      // transition: 0.3s all ease-in-out;
      padding: 0;
      // line-height: 1.875rem;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      padding-bottom: 0.854166em;
      // span {
      //   border-bottom: 2px solid $color-E8C339;
      //   padding-bottom: 0.854166em;
      // }
    }

    .lefttab.active::after {
      position: absolute;
      content: "";
      height: 2px;
      background-color: $color-E8C339;
      width: 57.099%;
      bottom: 0;
    }
  }
  //   tab section end

  .details_row {
    padding-top: 5.156em;
    margin: 0 auto;
    .details_col {
      padding: 0;
      .details_heading {
        font-size: 2.188em;
        // line-height: 4.063rem;
        line-height: 1.857;
        color: $color-E8C339;
        font-weight: 600;
      }
      .details_subheading {
        font-size: 2.188em;
        // line-height: 4.063rem;
        line-height: 1.857;
        color: $color-1C1C1C;
        font-weight: 600;
        border-bottom: 1px solid #adadad;
      }
      .recommended {
        color: $color-1C1C1C;
        .recommended_heading {
          font-weight: 600;
          font-size: 1.5em;
          // line-height: 4.063rem;
          margin: 1.3125em 0 1.042em;
        }
        .recommended_list {
          li {
            font-size: 1.25em;
            font-weight: 400;
            // line-height: 3.125rem;
            margin-bottom: 1.25em;
            // list-style-type: disc;
            // list-style-position: inside;
            // display: inline;
            // margin-left: 1em;
            // .list_block {
            //   display: inline;
            // }
          }
        }
      }
      .img_wrapper {
        text-align: center;
        padding: 5.625em 10.938em;
        border: 1px solid #adadad;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 4em;
        .product_img {
          width: 100%;
          max-width: 27.713em;
          // max-width: 49.72em;
          // max-width: 100%;
          transform: scale(1);
          -webkit-transform: scale(1);
          transition: 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
          }
        }
      }
      &:nth-child(2n + 2) {
        padding-left: 3.938em;
      }
      &:nth-child(2n + 1) {
        padding-right: 3.938em;
      }

      .description {
        color: $color-1C1C1C;
        .dec_heading {
          font-size: 1.5em;
          line-height: 4.063rem;
          font-weight: 600;
        }
        .text {
          margin-bottom: 1.55em;
        }
      }

      // .table_wrapper {
      //   .table_title {
      //     background-color: $color-E8C339;
      //     font-size: 1.5em;
      //     line-height: 3.625rem;
      //     font-weight: 600;
      //     text-align: center;
      //     margin-bottom: 0.417em;
      //   }
      //   .product_table {
      //     display: inline-table;
      //     border-collapse: separate;
      //     border-spacing: 0.5em 0;
      //     text-align: center;
      //     width: 100%;
      //     max-width: 100%;
      //     // border: 1px solid #70707047;
      //     // margin-left: -0.5em;
      //     // margin-right: -0.5em;
      //     margin: 0 -0.5em;
      //     // width: calc(100% + 1000px);
      //     th {
      //       color: $color-1C1C1C;
      //       background-color: $color-E8C3391A;
      //       font-size: 1.5em;
      //       font-weight: 600;
      //       line-height: 3.525rem;
      //     }
      //     td {
      //       font-size: 1.25em;
      //       font-weight: 400;
      //       color: $color-1C1C1C;
      //       line-height: 2rem;
      //     }
      //     th,
      //     td {
      //       border: 1px solid #70707047;
      //     }
      //     .first_row {
      //       width: 100%;
      //       max-width: 70%;
      //     }
      //     .second_row {
      //       width: 100%;
      //       max-width: 30%;
      //       margin-right: -10em;
      //     }
      //   }
      // }
    }
  }

  .keyfeatures_row {
    margin: 0 auto;
    .keyfeatures {
      font-size: 1.5em;
      font-weight: 600;
      // line-height: 4.063rem;
      margin: 0.833333em 0 1.042em;
    }
    .keyfeatures_col {
      color: $color-1C1C1C;
      padding: 0;
      margin-bottom: 2.313em;
      text-align: center;
      .keyfeature_img {
        width: 100%;
        max-width: 5.438em;
        margin-bottom: 0.688em;
      }
      .keyfeature_desc {
        font-size: 1.25em;
        // line-height: 2rem;
        line-height: 1.6;
        width: 100%;
        text-transform: capitalize;
        font-weight: 400;
        max-width: 12em;
        margin: 0 auto;
      }
    }
  }
  // old table start
  // .table_wrapper {
  //   .table_title {
  //     background-color: $color-E8C339;
  //     font-size: 1.5em;
  //     line-height: 3.625rem;
  //     font-weight: 600;
  //     text-align: center;
  //     margin-bottom: 0.417em;
  //   }
  //   .product_table_row {
  //     overflow-x: auto;
  //     justify-content: space-between;
  //     margin: 0 auto;

  //     .first_col,
  //     .second_col {
  //       font-size: 1.25em;
  //       font-weight: 400;
  //       color: $color-1C1C1C;
  //       line-height: 2rem;
  //       padding: 0;
  //       text-align: center;
  //       border-top: 1px solid #70707069;
  //       border-left: 1px solid #70707069;
  //       border-right: 1px solid #70707069;
  //       &:last-child {
  //         border-bottom: 1px solid #70707069;
  //       }
  //       &:nth-last-child(2) {
  //         border-bottom: 1px solid #70707069;
  //       }
  //     }
  //     .first_col {
  //       max-width: 70%;
  //       flex: 0 0 70%;
  //     }
  //     .second_col {
  //       flex: 0 0 28.4777%;
  //       max-width: 28.4777%;
  //     }
  //     .table_heading {
  //       // color: $color-1C1C1C;
  //       background-color: $color-E8C3391A;
  //       font-size: 1.5em;
  //       font-weight: 600;
  //       line-height: 3.525rem;
  //     }
  //   }
  // }
  // old table end

  .table_wrapper {
    overflow-x: auto;
    .table_title {
      background-color: $color-E8C339;
      font-size: 1.5em;
      line-height: 3.625rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0.417em;
    }
    .product_table {
      width: 100%;
      max-width: 100%;
      // overflow-x: auto;
      justify-content: space-between;
      margin: 0 auto;

      // .first_col {
      //   width: 100%;
      //     max-width: 15.525em;
      // }

      // .second_col {
      //   width: 100%;
      //   max-width: 11.438em;
      // }

      // .first_col1 {
      //   width: 100%;
      //   max-width: 15.525em;
      // }
      // .second_col1 {
      //   width: 100%;
      //   max-width: 11.438em;
      // }

      .table_heading {
        th {
          font-size: 1.5em;
          font-weight: 600;
          line-height: 3.525rem;
          text-align: center;
        }
        .margin_left {
          margin-left: 0.4166666em;
        }
      }
      th {
        // width: 100%;
        // max-width: 74.594%;
        width: 74.594%;
        user-select: text;
      }
      td {
        font-size: 1.25em;
        font-weight: 400;
        color: $color-1C1C1C;
        line-height: 2rem;
        text-align: center;
        width: 28.237%;
        // width: 100%;
        // max-width: 28.237%;
        height: 2.1em;
        user-select: text;
      }

      .table_heading,
      .table_data {
        p {
          padding: 0;
          text-align: center;
          border-top: 1px solid #70707069;
          border-left: 1px solid #70707069;
          border-right: 1px solid #70707069;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .table_data:last-child p {
        border-bottom: 1px solid #70707069;
      }

      .table_heading {
        p {
          background-color: rgba(232, 195, 57, 0.1019607843);
        }
      }
      .margin_left {
        // width: 100%;
        // max-width: 28.237%;
        margin-left: 0.5em;
      }
    }
  }
}

.products_sec4 {
  padding: 0 0 4.375em;
  .subheading {
    text-align: center;
  }
  .resource_wrapper {
    margin: 3.118em 0 0;
    padding: 4.813em 0 5em;
    background: rgba($color: $color-E7C95B, $alpha: 0.1);
    .my_container {
      padding: 0 4.875em;
    }
    .resource_box {
      margin: 0 1.688em;
      padding: 4.438em 1em 5.375em;
      background: $color-ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        width: 100%;
      }
      .mail_icon {
        max-width: 4.201em;
      }
      .doc_icon {
        max-width: 3.25em;
      }
      .subheading {
        font-size: 1.75em;
        line-height: 1.357em;
        margin: 0.714em 0 0;
      }
      &:hover {
        border: 1px solid #e8c339;
      }
    }
  }
}

@media (max-width: 1536px) {
  .product_sec3 {
    .left_section {
      .lefttab {
        line-height: 1.563rem;
      }
    }
  }
}

@media (max-width: 1440px) {
  .products_sec4 {
    .resource_wrapper {
      .my_container {
        padding: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .product_sec3 {
    .product_slider_row {
      .product_slider_col {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
    .details_row {
      .details_col {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .details_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .details_col:nth-child(2n + 2) {
        padding-left: 0;
      }
      .cta_wrapper {
        margin-bottom: 2.5em;
      }
    }
  }
}

@media (max-width: 768px) {
  .product_sec2 {
    padding: 4.2em 0;
  }

  .product_sec3 {
    padding-bottom: 4.205em;
    margin-bottom: 3.592em;
    &::after {
      max-width: 91.19%;
    }
    .mbdetails_tab {
      margin-bottom: 2.4em;
      .mb_details_tab {
        appearance: none;
        -webkit-appearance: none;
        background-color: $color-ffffff;
        border: none;
        border-bottom: 2px solid $color-E8C339;
        background-image: url("../../source/images/General/down_arrow.svg");
        background-repeat: no-repeat;
        background-size: 1.05em;
        background-position: 100% 40%;
        color: $color-1B1B1B;
        font-size: 1.6em;
        line-height: 1.75rem;
        font-weight: 600;
        padding-bottom: 0.5em;
        padding-right: 1.5em;
        width: 100%;
        max-width: 100%;
        &:focus {
          outline: none;
        }
      }
    }
    .details_row {
      padding-top: 0.35em;
      .details_col {
        text-align: center;
        .details_heading {
          font-size: 1.8em;
          line-height: 3.063rem;
        }
        .details_subheading {
          font-size: 1.8em;
          line-height: 2.063rem;
        }
        .recommended {
          .recommended_heading {
            font-size: 1.6em;
            line-height: 2.5rem;
            margin: 0;
            margin-top: 1em;
          }
          .recommended_list {
            li {
              font-size: 1.4em;
              line-height: 1.875rem;
              margin-bottom: 0.5em;
              .list_block {
                display: block;
                margin-top: 0.5em;
              }
            }
          }
        }
        .img_wrapper {
          padding: 2.9em 5.876em 1.274em 6.363em;
          .product_img {
            max-width: 22.445em;
          }
        }
        .description {
          .dec_heading {
            font-size: 1.6em;
            margin-top: 1.5em;
          }
        }
        .description {
          .text {
            margin-bottom: 3.2144em;
          }
        }
      }
      .details_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .details_col:nth-child(2n + 2) {
        padding-left: 0;
      }
    }
    .keyfeatures_row {
      .keyfeatures {
        font-size: 1.6em;
      }
      .keyfeatures_col {
        margin-bottom: 2.4em;
        .keyfeature_img {
          max-width: 4.7em;
          margin-bottom: 0.9em;
        }
        .keyfeature_desc {
          font-size: 1.4em;
          // line-height: 1.5rem;
          line-height: 1.715em;
        }
      }
    }

    // old table start
    // .table_wrapper {
    //   .table_title {
    //     font-size: 1.6em;
    //     line-height: 2.5rem;
    //     margin-bottom: 0.75em;
    //   }
    //   .product_table_row {
    //     .table_heading {
    //       font-size: 1.6em;
    //       line-height: 2.45rem;
    //     }
    //     .first_col,
    //     .second_col {
    //       font-size: 1.4em;
    //     }
    //     .first_col {
    //       max-width: 59.9655%;
    //       flex: 0 0 59.9655%;
    //     }
    //     .second_col {
    //       flex: 0 0 37.6596%;
    //       max-width: 37.6596%;
    //     }
    //   }
    // }
    // old table end

    .table_wrapper {
      .table_title {
        font-size: 1.6em;
        line-height: 2.5rem;
        margin-bottom: 0.75em;
      }
      .product_table {
        .table_heading {
          th {
            font-size: 1.6em;
            line-height: 2.45rem;
          }
          .margin_left {
            margin-left: 0.5em;
          }
        }
        td {
          font-size: 1.4em;
        }
        th {
          // max-width: 59.9655%;
          // flex: 0 0 59.9655%;
          width: 59.9655%;
        }
        td {
          // flex: 0 0 37.6596%;
          // max-width: 37.6596%;
          width: 37.6596%;
        }
        .margin_left {
          margin-left: 0.5714288em;
        }
      }
    }
  }

  .products_sec4 {
    .resource_wrapper {
      padding: 4.813em 15px 5em;
      .resource_box {
        margin: 1.688em;
      }
    }
  }
}

// .table_wrapper {
//   .table_title {
//     background-color: $color-E8C339;
//     font-size: 1.5em;
//     line-height: 3.625rem;
//     font-weight: 600;
//     text-align: center;
//     margin-bottom: 0.417em;
//   }
//   .product_table {
//     width: 100%;
//     max-width: 100%;
//     overflow-x: auto;
//     justify-content: space-between;
//     margin: 0 auto;

//     .table_heading {
//       th {
//         font-size: 1.5em;
//         font-weight: 600;
//         line-height: 3.525rem;
//         text-align: center;
//       }
//       .margin_left {
//         margin-left: 0.4166666em;
//       }
//     }
//     th {
//       width: 100%;
//       max-width: 74.594%;
//     }
//     td {
//       font-size: 1.25em;
//       font-weight: 400;
//       color: $color-1C1C1C;
//       line-height: 2rem;
//       width: 100%;
//       max-width: 28.237%;
//     }

//     .table_heading,
//     .table_data {
//       p {
//         padding: 0;
//         text-align: center;
//         border-top: 1px solid #70707069;
//         border-left: 1px solid #70707069;
//         border-right: 1px solid #70707069;
//       }
//     }

//     .table_heading {
//       p {
//         background-color: rgba(232, 195, 57, 0.1019607843);
//       }
//     }
//     .margin_left {
//       margin-left: 0.5em;
//     }
//   }
// }
