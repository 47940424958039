@import "../../common.scss";

.news_sec2 {
  padding: 6.563em 0 6.813em;
  .news_row {
    justify-content: space-between;
    .news_col {
      padding: 0 2em;
    }
    .spinner {
      .spin_gif {
        max-width: 7em;
      }
    }
  }
  .news_box {
    margin: 0 0 5.625em;
    .img_wrapper {
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      z-index: 5;
      border-radius: 7.5px;
    }
    .news_img {
      width: 100%;
      max-width: 100%;
      transform: scale(1);
      transition: 0.5s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    .text_container {
      color: $color-1B1B1B;
      padding: 2em 0 0;
      .title {
        font-size: 1.5em;
        line-height: 1.458em;
      }
      font-weight: bold;
      .desc {
        font-size: 1.25em;
        padding: 0.8em 0;
        font-weight: normal;
      }
      .cta_wrapper {
        .cta {
          font-size: 1.125em;
          line-height: 1.944em;
          text-decoration: underline;
          margin-top: 1.2em;
          color: $color-1B1B1B;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .news_sec2 {
    .news_row {
      .news_col {
        padding: 0 15px;
      }
    }
  }
}
