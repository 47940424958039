@import "../../common.scss";

.enquiry_sec1 {
  // padding: 5.875em 0 7.438em;
  padding: 0 0 7.438em;
  .contact_row {
    align-items: center;
    border-top: 1px solid #adadad;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 4.659em;
    .heading {
      padding: 0;
    }
    .contact_col {
      padding: 0;
      .white_arrow {
        width: 100%;
        max-width: 4.938em;
        // float: right;
      }
    }
    .contact_md3 {
      max-width: 20%;
    }
    .contact_md8 {
      max-width: 75%;
    }
    .desk_btn {
      width: 100%;
      max-width: 4.938em;
      height: 4.938em;
        border: none;
        border-radius: 50%;
        background-color: $color-C2A877;
        float: right;
    }
    .spinner_img {
      width: 100%;
      max-width: 100%;
      background-color: transparent;
    }
    .spinner-grow {
      background-color: transparent;
    }
  }
  .contact_row2 {
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .contact_col2 {
      // padding: 0 2em;
      max-width: 47%;
      font-size: 1.25em;
    }
    .contact_input {
      padding-left: 0;
      padding-right: 0;
    }
    // .contact_input:focus {
    //   padding-top: 0.625em;
    // }
    .form-floating > .form-control:focus ~ label {
      transform: scale(0.85) translateY(-1.5em) translateX(0.15em);
    }
    .form-floating>.form-control:not(:placeholder-shown)~label {
      transform: scale(.85) translateY(-1em) translateX(.15em);
    }
    .form-floating > label {
      top: -10%;
    }
  }
}

@media (max-width: 1536px) {
  .enquiry_sec1 {
    .contact_row2 {
      .contact_input {
          height: calc(2.5em + 2px);
      }
      .form-floating > label {
        top: 0;
      }
    }
  }
}

@media (max-width: 1080px) {
  .enquiry_sec1 {
    .contact_row {
      .contact_md3 {
        max-width: 22%;
      }
    }
  }
}

@media (max-width: 992px) {
  .enquiry_sec1 {
    .contact_row {
      // .contact_md3 {
      //   max-width: 100%;
      // }
      // .contact_md8 {
      //   max-width: 100%;
      // }
      .contact_col {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .contact_col {
        .white_arrow {
          margin-top: 2.5em;
        }
      }
    }
   .contact_row2 {
    .contact_col2 {
      max-width: 48%;
      flex: 0 0 48%;
      padding: 0;
    }
    // .contact_input {
    //   padding-left: 0;
    //   padding-right: 0;
    // }
   .form-floating > label {
    padding-left: 0;
      padding-right: 0;
   }
  }
    .cta_wrapper {
      max-width: 30em;
      margin: 0 auto;
      margin-top: 3em;
      padding: 0;
    }
  }
}

@media (max-width: 767px) {
  .enquiry_sec1 {
    padding: 0 0 3.2em;
    .contact_row {
      .contact_col {
        max-width: 100%;
        .subheading {
          margin-bottom: 0.025em;
        }
      }
      padding-top: 3.2em;
      .heading {
        padding: 0;
      }
    }
    .contact_row2 {
      .contact_col2 {
        max-width: 100%;
        flex: 0 0 100%;
        font-size: 1.4em;
        margin-top: 0.46em;
        margin-bottom: 1.357em;
      }
      .contact_input {
        // font-size: 1.4em;
        height: calc(2.75rem + -2px);
        line-height: 1.188rem;
        padding: 0.663266em 0;
      }
      // .contact_input:focus {
      //   padding-top: 1em;
      // }
      .form-floating > .form-control:focus ~ label {
        transform: scale(0.85) translateY(-1.5em) translateX(0.15em);
      }
      .form-floating > label {
        top: -10%;
      }
      .form-floating > .form-control:not(:placeholder-shown) ~ label {
        transform: scale(0.85) translateY(-1.5em) translateX(0.15em);
      }
    }

    .cta_wrapper {
      width: 100%;
      max-width: 28.1em;
      margin: 0 auto;
      margin-top: 2.82em;
    }
  }
}
