@import "../../common.scss";

// .ind_sec1 {
//   padding-top: 8.813em;
// }

.ind_sec2 {
  padding: 6.063em 0 4.813em;
  .ind_row {
    margin: 0 auto;
    .ind_col {
      padding: 0;
      .text {
        margin-bottom: 0.8em;
      }
    }
    .ind_col:nth-child(2n + 1) {
      padding-right: 4.156em;
    }
    .ind_col:nth-child(2n + 2) {
      padding-left: 4.156em;
    }
  }
}

.ind_sec3 {
  padding-bottom: 6.75em;
  .material_swiper {
    position: relative;
    z-index: 2;
    .material_arrow {
      .material_left_arrow,
      .material_right_arrow {
        position: absolute;
        width: 100%;
        max-width: 4.938em;
        height: 4.938em;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .material_left_arrow {
        left: -1.5em;
      }
      .material_right_arrow {
        right: -1.5em;
      }
    }
  }
  .material_slider {
    .img_wrapper {
      border-radius: 10px;
      overflow: hidden;
      .material_img {
        width: 100%;
        max-width: 100%;
        // max-width: 31.875em;
        // opacity: 0.57;
        transform: scale(1);
        -webkit-transform: scale(1);
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
        }
      }
    }
    .material_details {
      position: absolute;
      left: 2.125em;
      bottom: 1.563em;
      color: $color-ffffff;
      .material_title {
        font-size: 1.25em;
        margin-bottom: 0.4em;
        font-weight: 400;
        // line-height: 2.188rem;
      }
      .material_desc {
        font-size: 1.5em;
        font-weight: 600;
        // line-height: 4.375rem;
      }
      .material_link {
        display: block;
        font-size: 1.25em;
        margin-top: 0.85em;
        font-weight: 400;
        text-decoration: underline;
        color: inherit;
      }
    }
  }

  //   tab section start
  .left_section {
    ul {
      padding: 0;
      display: flex;
      justify-content: flex-start;
    }
    .lefttab {
      position: relative;
      font-size: 1.5em;
      // line-height: 4.375rem;
      line-height: 2.375rem;
      letter-spacing: 0;
      color: $color-000000;
      font-weight: 600;
      opacity: 0.4;
      cursor: pointer;
      padding: 0;
      margin: 0;
      // transition: 0.3s all ease-in-out;
      margin: 0 3.71em 1.875em 0;
      border-bottom: 0px solid $color-E8C339;
    }
    .lefttab.active {
      color: $color-1B1B1B;
      background-size: contain;
      font-weight: 600;
      position: relative;
      opacity: 1;
      // transition: 0.3s all ease-in-out;
      padding: 0;
      // line-height: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid $color-E8C339;
      .arrow_right {
        display: inline;
      }
    }
    // .lefttab.active::after {
    //   width: 100%;
    // }
  }
  //   tab section end
}

.ind_sec4 {
  background-image: url("../../source/images/industries/ind_usp.png");
  background-repeat: no-repeat;
  background-size: cover;
  // padding: 6.75em 0 7.875em;
  // margin-bottom: 6.188em;
  padding: 6.75em 0 10.063em;
  margin-bottom: 7.5em;
  .usp_row {
    margin: 0 auto;
    .usp_col {
      padding: 0;
      .ind_usp_img {
        width: 100%;
        max-width: 5.438em;
      }
      .usp_head {
        font-size: 2.25em;
        // line-height: 3.125rem;
        line-height: 1.388;
        color: $color-E8C339;
        font-weight: 400;
        margin: 0.7225em 0;
      }
      .usp_list li {
        font-size: 1.25em;
        list-style-type: disc;
        // line-height: 2.375rem;
        line-height: 1.904;
        font-weight: 400;
        margin-left: 1em;
        color: $color-ffffff;
      }
    }
    .usp_col:nth-child(2n + 1) {
      padding-right: 10.969em;
    }
    .usp_col:nth-child(2n + 2) {
      padding-left: 4.969em;
      padding-right: 7.6em;
    }
  }
}

@media (max-width: 1536px) {
  .ind_sec3 {
    .left_section {
      .lefttab {
        line-height: 1.875rem;
      }
    }
  }
}

@media (max-width: 835px) {
  .ind_sec4 {
    .usp_row {
      .usp_col {
        text-align: center;
        .usp_list {
          padding-bottom: 4.21em;
          li {
            list-style-position: inside;
            margin-left: 0;
          }
        }
      }
      .usp_col:nth-child(2n + 2) .usp_list {
        padding-bottom: 0;
      }
      .usp_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .usp_col:nth-child(2n + 2) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  // .ind_sec1{
  //     padding-top: 8em;
  // }
  .ind_sec2 {
    padding: 4.207em 0 4.1em;
    .ind_row {
      .ind_col {
        text-align: center;
        .text {
          margin-bottom: 2.145em;
        }
      }
      .ind_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .ind_col:nth-child(2n + 2) {
        padding-left: 0;
      }
    }
    .cta_wrapper {
      margin: 0 auto;
    }
  }

  .ind_sec3 {
    padding-bottom: 2em;
    // padding-bottom: 4em;
    .material_slider {
      // margin-bottom: 4em;
      margin-bottom: 6em;
      .img_wrapper {
        .material_img {
          max-width: 100%;
        }
      }
      .material_details {
        // bottom: 1.593em;
        bottom: 3.1em;
        text-align: center;
        left: 0;
        right: 0;
        .material_title {
          font-size: 1.2em;
          margin-bottom: 0.56em;
          // line-height: 2.188rem;
        }
        .material_desc {
          font-size: 1.8em;
          // line-height: 4.375rem;
        }
        .material_link {
          font-size: 1.4em;
          margin-top: 0.802em;
        }
      }
    }
    .material_swiper {
      .material_arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        .material_left_arrow,
        .material_right_arrow {
          position: relative;
        }
        .material_right_arrow {
          right: 0;
        }
        .material_left_arrow {
          left: 0;
          margin-right: 1.2em;
        }
      }
    }
    .mb_dropdown {
      margin-bottom: 2.4em;
      .mb_select {
        appearance: none;
        -webkit-appearance: none;
        background-color: $color-ffffff;
        border: none;
        border-bottom: 2px solid $color-E8C339;
        background-image: url("../../source/images/General/down_arrow.svg");
        background-repeat: no-repeat;
        background-size: 1.05em;
        background-position: 100%;
        color: $color-1B1B1B;
        font-size: 1.6em;
        line-height: 1.75rem;
        font-weight: 600;
        padding-bottom: 0.5em;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .ind_sec4 {
    padding: 3.9em 0 3.9em;
    margin-bottom: 4.1em;
    .usp_row {
      .usp_col {
        text-align: center;
        // margin-top: 3.9em;
        .ind_usp_img {
          max-width: 4.7em;
        }
        .usp_head {
          font-size: 1.8em;
          line-height: 1.75rem;
          margin: 0.889em 0 0.666666em;
        }
        .usp_list {
          padding-bottom: 4.21em;
          li {
            font-size: 1.4em;
            list-style-position: inside;
            // line-height: 1.625rem;
            line-height: 1.856;
            margin-left: 0;
          }
        }
      }
      .usp_col:nth-child(2n + 2) .usp_list {
        padding-bottom: 0;
      }
      .usp_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .usp_col:nth-child(2n + 2) {
        padding-left: 0;
        padding-right: 0;
        // margin-top: 0;
      }
    }
  }
}
