@import "../../common.scss";

.logo-container {
  position: absolute;
  z-index: 9999999;
  img {
    width: 100%;
    max-width: 189px;
  }
}
.comingsoon_sec {
  display: flex;
  width: 100%;
  .comingsoonbanner {
    width: 50%;
    z-index: 9999;
    img {
      width: 100%;
      height: 100vh;
      background-size: cover;
      object-fit: fill;
    }
  }
  .comingsoontext {
    width: 50%;
    img {
      width: 100%;
      height: 100vh;
      object-fit: fill;
    }
  }
}

@media (max-width: 1023px) {
  .logo-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .comingsoon_sec {
    flex-direction: column;
    .comingsoonbanner {
      width: 100%;
      img {
        height: 60vh;
        background-size: cover;
      }
    }
    .comingsoontext{
      width: 100%;
      img{
        height: 85vh;
      }
    }
  }
}
