@import "../../common.scss";

.common_banner_wrapper {
  position: relative;
  // margin-top: 8.813em;
  margin-top: 8.25em;
  .common_bannerimg {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  .breadcrumb_content_wrapper {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    max-width: 100%;
    .breadcrumb_text {
      color: $color-ffffff;
      font-size: 1.125em;
      line-height: 1.875rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.9px;
      .fa-greater-than {
        transform: scaleX(0.6);
      }
    }
  }
  // .text_container {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   text-align: center;
  //   width: 100%;
  // }

  .breadcrumb_text.disabled {
    pointer-events: none;
  }
  .banner_heading {
    font-size: 3.125em;
    font-weight: 600;
    margin-top: 0.25em;
    line-height: 4.063rem;
    color: $color-ffffff;
    text-transform: capitalize;
  }
}

@media (max-width: 1280px) {
  .common_banner_wrapper {
    .banner_heading {
      line-height: 2.063rem;
    }
  }
}

@media (max-width: 1024px) {
  .common_banner_wrapper {
    margin-top: 9.5em;
  }
}

@media (max-width: 767px) {
  .common_banner_wrapper {
    margin-top: 8em;
    // margin-top: 6.7em;
    .common_bannerimg {
      min-height: 14.5em;
    }
    .breadcrumb_content_wrapper {
      .breadcrumb_text {
        font-size: 1.4em;
        letter-spacing: 0.7px;
        line-height: 1.875rem;
        font-weight: 600;
      }
    }
    .banner_heading {
      font-size: 2.8em;
      line-height: 3.063rem;
      // margin-top: 0.232em;
      margin-top: 0;
    }
  }
}
