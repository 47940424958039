@import "../../common.scss";

.terms_sec1 {
  .common_banner_wrapper {
    .breadcrumb_content_wrapper {
      .breadcrumb_text {
        color: $color-1B1B1B;
      }
    }
    .banner_heading {
      color: $color-1C1C1C;
    }
  }
}

.terms_sec2 {
  // padding: 0.313em 0 3.75em;
  padding: 3.75em 0 3.75em;
  counter-reset: heading;
  .para_spacing {
    margin-bottom: 1.3em;
  }
  .terms_heading {
    color: $color-323232;
    font-size: 1.75em;
    font-weight: 600;
    // line-height: 2.625rem;
    line-height: 1.5;
    margin: 1.9643em 0 0.8572em;
    &::before {
      counter-increment: heading;
      content: counter(heading) ". ";
    }
  }
  .terms_content {
    color: $color-323232;
    font-size: 1.125em;
    font-weight: 400;
    // line-height: 1.875rem;
    line-height: 1.65;
    letter-spacing: 0.9px;
  }
  .terms_list {
    margin-top: 1.777777em;
    li {
      list-style-type: disc;
      margin-left: 1em;
      &::marker {
        color: $color-E8C339;
      }
    }
  }
}

@media (max-width: 767px) {
  .terms_sec2 {
    padding: 4.2em 0 3.553em;
    // .content_wrapper:nth-child(1) {
    //   margin-top: 0;
    // }
    .para_spacing {
      margin-bottom: 1em;
    }
    .terms_heading {
      color: $color-1B1B1B;
      margin: 2.3335em 0 0.803em;
      font-size: 1.8em;
      line-height: 1.75rem;
    }
    // .terms_heading:first-child {
    //     margin-top: 0;
    //   }
    // .terms_heading1 {
    //     margin-top: 0;
    // }
    .terms_content {
      color: $color-1B1B1B;
      font-size: 1.4em;
      letter-spacing: 0.7px;
      line-height: 1.313rem;
    }
    .terms_list {
      margin-top: 1.029em;
      li {
        margin-left: 1.15em;
      }
    }
  }
}
