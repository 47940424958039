@import "../../common.scss";

.footer_sec1 {
  padding: 5.375em 0 4.25em;
  background-color: #2a2a2a0d;
  // position: relative;

  .footer_row {
    position: relative;
    .footer_col {
      .logo_wrapper {
        .footer_logo {
          width: 100%;
          // max-width: 22.853em;
          max-width: 16.798em;
          // margin: -1.15em 0 0 -4em;
        }
      }
      .links_wrapper {
        .link_heading {
          font-size: 1.75em;
          font-weight: 600;
          line-height: 2.5rem;
          // margin-bottom: 1.036em;
          margin-bottom: 1.195em;
          color: $color-000000;
          opacity: 0.8;
        }
        .link_list {
          li {
            // margin-bottom: 1.75em;
            margin-bottom: 0.938em;
            .link {
              font-size: 1.125em;
              // line-height: 2.5rem;
              color: $color-1B1B1B;
              font-weight: 500;
            }
          }
        }
        .link_list1 {
          li {
            margin-bottom: 1.75em;
            .link1 {
              font-size: 1.125em;
              line-height: 1.75rem;
              color: $color-1B1B1B;
              font-weight: 500;
            }
          }
          li:nth-child(1) .link1 {
            cursor: default;
          }
        }
      }
    }
// scroll to top start
.scroll_wrap {
  position: absolute;
  top: -8em;
  right: 0;
  text-align: right;
  cursor: pointer;
  .scrollup_img {
    width: 100%;
    max-width: 4.938em;
  }
}
// scroll to top end
  }

  // mobile footer start
  .mbfooter_col:nth-child(n + 2) {
    margin-top: 2.6em;
  }
  .mb_menu {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
    transition: all 0.3s;
  }
  .mb_menu.mb_menu_active {
    left: 0;
  }
  .mb_links_wrapper_cont {
    width: 100%;
    overflow-y: auto;
    // .catalogue_btn {
    //   background-color: $color-C2A877;
    //   padding: 0.8em 2em;
    //   border-radius: 50px;
    //   border: none;
    //   font-size: 1.6em;
    //   .route_link:hover {
    //     color: #000;
    //   }
    // }
  }
  .mb_link_wrapper {
    .mb_route_text {
      color: $color-000000;
      font-size: 1.6em;
      font-weight: 600;
      // line-height: 2.5rem;
      outline: none;
      margin-bottom: 0.75em;
    }
    .mb_link_main_wrapper {
      margin: 0 auto;
      position: relative;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
    .mb_sub_menu_wrapper {
      // display: none;
      .mb_sub_link_wrapper {
        padding-bottom: 1.5em;
      }
    }

    .box_content_wrapper {
      color: $color-000000;
      margin-top: 1.2em;
    }
    .plus_span {
      position: absolute;
      padding-left: 0.25em;
      font-size: 1.2em;
      line-height: 1.563rem;
    }
  }
  .link_name_wrapper {
    margin-top: 1.2em;
  }
  .link_name {
    color: $color-000000;
    font-size: 1.2em;
    font-weight: 500;
    // line-height: 1.563rem;
  }
  // .box_content_wrapper {
  //   display: none;
  // }
  .box_content_active.box_content_wrapper {
    display: block;
  }
  // mobile footer end
}

.footer_sec2 {
  padding: 1.188em 0 1.091em;
  background-color: $color-E8C339;
  .copy_right_container {
    text-align: center;
    .copyright_text {
      color: $color-1B1B1B;
      font-size: 0.875em;
      line-height: 21px;
      font-weight: 500;
    }
    .copyright_crafted {
      color: inherit;
    }
  }
}

@media (max-width: 1536px) {
  .footer_sec1 {
    .footer_row {
      .footer_col {
        .links_wrapper {
          .link_list1 {
            li {
              .link1 {
                line-height: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer_sec1 {
    padding: 2.016em 0 0.6em;
   
    .footer_row {
      text-align: center;
      .footer_col {
        .logo_wrapper {
          margin-bottom: 2.438em;
          .footer_logo {
            // max-width: 19.035em;
            max-width: 16em;
            margin: 0 auto;
          }
        }
        .links_wrapper {
          .link_heading {
            font-size: 1.6em;
            margin: 0.8em 0 0.72em;
            // margin: 0.8em 0 0;
            opacity: 1;
          }
          .link_list1 {
            li {
              margin-bottom: 1.8em;
              .link1 {
                font-size: 1.2em;
                line-height: 1.563rem;
              }
            }
          }
        }
      }
      .scroll_wrap {
        top: -4.75em;
        .scrollup_img {
          max-width: 5.1em;
        }
      }
    }
  }

  .footer_sec2 {
    margin-bottom: 4.3em;
    .copy_right_container {
      .copyright_text {
        font-size: 1.2em;
      }
    }
    &.enquire {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 380px) {
  .footer_sec2 {
    margin-bottom: 4.15em;
  }
}
