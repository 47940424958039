@import "../../common.scss";
.recommend_main_container-1 {
  background-image: url(../../source/images/homepage/Exterior_coating.png);
  background-size: cover;
  width: 100%;
  max-width: 14em;
  height: 12em;
  max-height: 15em;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: end;
  padding-left: 12px;
  padding-top: 45px;
}
.recommend_main_container-2 {
  background-image: url(../../source/images/homepage/Wood_finishes_coating.png);
  background-size: cover;
  width: 100%;
  max-width: 14em;
  height: 12em;
  max-height: 15em;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: flex-end;
  padding-left: 12px;
  padding-top: 45px;
}
.recommend_main_container-3 {
  background-image: url(../../source/images/homepage/Industrial_spray_coating.png);
  background-size: cover;
  width: 100%;
  max-width: 14em;
  height: 12em;
  max-height: 15em;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: end;
  padding-left: 12px;
  padding-top: 45px;
}
.recommend_row {
  margin-left: -1em !important;
  margin-top: 1em !important;
}
.recommend-heading {
  color: #fff;
  font: normal normal 400 13px/35px Poppins;
}
.recommend-desc {
  font: normal normal 600 13px/20px Poppins;
  color: #fff;
}
.feature_list {
  display: flex;
  // flex-wrap: wrap;
  color: $color-E8C339;
  li {
    padding-bottom: 1rem;
    font: normal normal normal 15px/27px Poppins;
  }
}
.home_sec1 {
  // padding-top: 8.813em;
  padding-top: 8.25em;
  position: relative;
  .banner_sec {
    position: relative;
    .banner_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100vh;
      min-height: 90vh;
      // object-fit: fill;
      // filter: brightness(0.5);
    }
    .main_wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .my_container {
        position: relative;
      }
    }
    .mbbanner_img {
      width: 100%;
      max-width: 100%;
      margin-top: 8em;
    }
    .banner_content_box {
      position: absolute;
      top: 40%;
      left: 0;
      -ms-transform: translate(0%, 40%);
      transform: translate(0%, 40%);
    }
    .banner_tio2_text {
      position: absolute;
      top: 0;
      // right: 22em;
      right: 21em;
      .banner_tio2 {
        color: $color-E8C339;
        background-color: $color-ffffff;
        font-size: 12.438em;
        font-weight: bold;
        line-height: 1.402;
        padding: 0.8442em 0.201em 0.2764em 0.2061em;
      }
      .banner_tio2_sub {
        bottom: -0.5em;
        font-size: 0.575em;
      }
    }
    .banner_heading {
      color: $color-ffffff;
      font-size: 4.063em;
      font-weight: bold;
      line-height: 1.34;
      margin-bottom: 0.4527655em;
      .color_text {
        color: $color-E8C339;
      }
    }
    .banner_sub_heading {
      color: $color-ffffff;
      font-size: 1.25em;
      font-weight: 500;
      // line-height: 2.188rem;
      line-height: 1.75;
      margin-bottom: 1.2em;
    }
    .banner_text_block {
      display: block;
    }
    .cta_wrapper {
      width: 100%;
      max-width: 16.938em;
      animation-name: fadeInUp;
    }
    .banner_arrows_wrapper {
      position: absolute;
      top: 0;
      // bottom: 13.188em;
      bottom: 8.188em;
      left: 8.75em;
      right: 8.75em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .banner_left_arrow,
      .banner_right_arrow {
        width: 100%;
        max-width: 4.188em;
        height: 4.188em;
        object-fit: contain;
        z-index: 1;
        cursor: pointer;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    .swiper-pagination {
      // transform: translateY(-15em);
      bottom: 23vh;
    }
    .swiper-pagination-bullet {
      width: 100%;
      max-width: 1em;
      height: 1em;
      background: #fff;
      opacity: 0.64;
    }
    .swiper-pagination-bullet-active {
      background: $color-E8C339;
      opacity: 1;
    }

    .details_container {
      margin-bottom: 0.969em;
    }
    .details_row {
      position: absolute;
      top: 5.458em;
      left: 0;
      right: 0;
      width: 100%;
      // max-width: 67.292%;
      max-width: 70.648%;
      margin: 0 auto;
      .details_col {
        padding: 0;
        &:nth-child(1) {
          padding-right: 2em;
        }
        &:nth-child(2) {
          padding-left: 7.519em;
          // padding-left: 8em;
        }
      }
      .details_col_content {
        width: 100%;
        max-width: 40.389em;
      }
      .details_heading {
        color: $color-E8C339;
        font-size: 2.375em;
        font-weight: 600;
        line-height: 1.447;
        letter-spacing: 0;
        border-bottom: 1px solid #ffffff99;
      }
      .details_subheading {
        color: $color-ffffff;
        font-size: 0.6316em;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2.33;
        margin-left: 0.771em;
      }
      .recommended {
        margin-top: 1.346em;
      }
      .recommended_heading {
        color: $color-ffffff;
        font-size: 1.25em;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2.7;
      }
      .recommended_list {
        color: $color-ffffff;
        font-size: 1.25em;
        letter-spacing: 0;
        line-height: 2.15;
      }
      .keyfeatures {
        color: $color-ffffff;
        font-size: 1.25em;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 2.7;
        padding: 0;
        margin-top: 0.822em;
        // margin-bottom: 1.4em;
        margin-bottom: 0.65em;
      }
      .keyfeatures_row {
        margin: 0 auto;
        margin-left: -2em;
        justify-content: flex-start;
      }
      .keyfeatures_col {
        padding: 0;
      }
      .keyfeature_col_wrapper {
        width: 100%;
        max-width: 97%;
        // max-width: 100%;
        text-align: center;
        margin-bottom: 1.76em;
      }
      // .keyfeature_img_wrapper {
      //   position: relative;
      //   display: inline-block;
      //   width: 100%;
      //   max-width: 4.683em;
      //   height: 4.683em;
      //   border-radius: 50%;
      //   border: 2px solid $color-E8C339;
      //   opacity: 1;
      // }
      .keyfeature_img {
        width: 100%;
        // max-width: 2.638em;
        max-width: 4.683em;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }
      .keyfeature_desc {
        color: $color-E8C339;
        font-size: 1.125em;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5;
        margin-top: 0.471em;
        // width: 100%;
        // max-width: 75%;
        // margin: 0 auto;
      }
      .details_product_img_wrapper {
        width: 100%;
        max-width: 32.313em;
        border: 1px solid #ffffff99;
        text-align: center;
      }
      .details_product_img {
        width: 100%;
        max-width: 22.338em;
        // margin: 2.69em 4.988em 0.516em;
        margin: 2.69em auto 0.516em;
      }
      .cta_wrapper {
        margin: 0 auto 2.438em;
      }
    }
  }
}

.home_sec2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 80%;
  .quality_row {
    background-color: $color-E8C339;
    border-radius: 10px;
    filter: drop-shadow(20px 20px 50px $color-0000001C);
    justify-content: center;
    text-align: center;
    padding: 4.563em 4.922em 4.5em;
    width: 100%;
    margin: 0 auto;
    .quality_col {
      padding: 0 4.109em;
      color: $color-1B1B1B;

      position: relative;
      // .quality_img {
      //   width: 100%;
      //   max-width: 8.125em;
      //   margin-bottom: 1.563em;
      // }
      .quality_heading {
        font-size: 1.5em;
        font-weight: 600;
        // line-height: 70px;
        // margin-bottom: 0.4167em;
        margin: 1.042em 0 0.4167em;
      }
      .quality_desc {
        font-size: 1.25em;
        line-height: 2.188rem;
        font-weight: 500;
      }

      // icon animation start

      .socialicons {
        display: flex;
        justify-content: center;
      }

      .socialicon {
        max-width: 8.125em;
        height: 8.125em;
      }

      .socialiconcircle1 {
        max-width: 8.125em;
        height: 8.125em;
      }

      .socialiconcircle2 {
        max-width: 6.625em;
        height: 6.625em;
      }

      .icons {
        max-width: 2.5em;
      }

      // icon animation end
    }
  }
}

.home_sec3 {
  padding: 21.376em 0 4.75em;
  .about_row {
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    .about_col {
      align-self: center;
      padding: 0;
    }
    .text_wrapper {
      padding-left: 6em;
    }
    .text {
      margin-bottom: 1.6em;
    }
    .cta_wrapper {
      width: 100%;
      max-width: 16.938em;
    }
  }
  .img_wrapper {
    padding-top: 6.375em;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    // max-width: 103.125em;
    .video_banner {
      width: 100%;
      max-width: 100%;
      // max-width: 103.125em;
      // height: 100%;
      // max-height: 40em;
    }
    .titax_youtube {
      width: 100%;
      max-width: 100%;
    }
    .play_btn {
      width: 100%;
      max-width: 8.375em;
      position: absolute;
      top: 54%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
}

.home_sec4 {
  .subheading {
    margin-bottom: 0.32em;
  }
  .text {
    margin-bottom: 2.299999em;
    width: 100%;
    max-width: 51.4em;
  }
  .product_row {
    // padding-bottom: 6.375em;
    margin-bottom: 6.653em;
    position: relative;
    .product_col {
      // padding: 0 3.203em;
      .product_container {
        height: 100%;
        min-height: 12em;
      }
      .img_wrapper {
        position: relative;
        border: 1px solid #adadad;
        border-radius: 10px;
        width: 100%;
        // max-width: 30.25em;
        max-width: 100%;
        margin-bottom: 1.5em;
        overflow: hidden;
        .product_img {
          width: 100%;
          max-width: 22.688em;
          display: block;
          margin: 0 auto;
          // padding: 3.625em 3.781em 1.063em;
          padding: 3.625em 0 1.063em;
          transform: scale(1);
          -webkit-transform: scale(1);
          transition: 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
          }
        }
      }
      .product_title {
        font-size: 1.5em;
        line-height: 2.188rem;
        color: $color-1B1B1B;
        font-weight: 600;
        margin-bottom: 0.8em;
        // .block {
        //   display: block;
        // }
      }
      .product_desc {
        font-size: 1.25em;
        font-weight: 400;
        line-height: 2.188rem;
        color: $color-1B1B1B;
      }
      .product_link {
        display: block;
        font-size: 1.25em;
        font-weight: 500;
        line-height: 1.8;
        color: $color-1B1B1B;
        text-decoration: underline;
        margin-top: 1.2em;
      }
    }
    .arrows_wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: -13.5%;
      right: 0;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 4.938em;
        height: 4.938em;
        cursor: pointer;
      }
      .left_arrow {
        margin-right: 1.75em;
      }
    }

    .mbarrows_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 4.938em;
        height: 4.938em;
        // height: 100%;
        // max-height: 4.938em;
        cursor: pointer;
      }
      .left_arrow {
        margin-right: 2.2em;
      }
    }

    // .mbarrows_wrapper {
    //   .left_arrow,
    //   .right_arrow {
    //     position: absolute;
    //     width: 100%;
    //     max-width: 4.938em;
    //     cursor: pointer;
    //     top: 25%;
    //     transform: translatey(-50%);
    //     z-index: 9;
    //   }
    //   .left_arrow {
    //     left: 0.5em;
    //   }
    //   .right_arrow {
    //     right: 0.5em;
    //   }
    // }
  }
  .cta_wrapper {
    width: 100%;
    max-width: 16.938em;
    margin: 0 auto;
    margin-top: 3.5em;
  }
}

.home_sec5 {
  padding: 7.875em 0 9.25em;
  background-color: $color-2A2A2A;
  border: 1px solid $color-707070;
  .app_row {
    margin-bottom: 4.625em;
    .app_col {
      // align-self: center;
      .subheading {
        color: $color-ffffff;
      }
      .text {
        color: $color-ffffff;
        opacity: 0.8;
        text-align: right;
      }
    }
  }

  .app_swiper {
    margin-bottom: 4.375em;
    // margin-bottom: 4.3em;
  }

  .app_wrapper {
    align-items: center;
    .cta_wrapper {
      width: 100%;
      max-width: 16.938em;
      .about-button {
        color: $color-C2A877;
      }
      .about-button:hover {
        color: $color-1B1B1B;
        border: none;
      }
    }
    .arrows_wrapper {
      display: flex;
      align-items: center;
      float: right;
      .appleft_arrow,
      .appright_arrow {
        width: 100%;
        max-width: 4.938em;
        height: 4.938em;
        // height: 100%;
        // max-height: 4.938em;
        cursor: pointer;
      }
      .appleft_arrow {
        margin-right: 1.75em;
      }
      .appwhiteleft_arrow,
      .appwhiteright_arrow {
        width: 100%;
        max-width: 4.938em;
        cursor: pointer;
      }
      .appwhiteleft_arrow {
        margin-right: 1.75em;
        transform: rotate(180deg);
      }
    }
  }

  .app_slider {
    position: relative;
    // margin-bottom: 4.375em;
    .img_wrapper {
      width: 100%;
      // max-width: 31.875em;
      max-width: 100%;
      border-radius: 10px;
      // opacity: 0.57;
      overflow: hidden;

      .app_img {
        width: 100%;
        opacity: 0.57;
        // max-width: 31.875em;
        transform: scale(1);
        -webkit-transform: scale(1);
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
        }
      }
    }
    .app_details {
      color: $color-ffffff;
      .app_title {
        font-size: 1.25em;
        line-height: 2.188rem;
        font-weight: 400;
        margin-bottom: 0.4em;
      }
      .app_desc {
        font-size: 1.5em;
        // line-height: 70px;
        font-weight: 600;
      }
    }
    .app_details {
      position: absolute;
      left: 2.125em;
      bottom: 1.563em;
    }
  }
}

.home_sec6 {
  text-align: center;
  // padding: 5.875em 0 2em;
  padding: 6.438em 0 8.063em;
  .supplier_products {
    padding: 0 0 8em;
    .row {
      justify-content: space-between;
      .col-md-6 {
        &:first-child {
          padding-right: 1.5em;
        }
        &:last-child {
          padding-left: 1.5em;
        }
      }
    }
    .highlight_card {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      &:hover .patch_wrapper .arrow {
        transform: scale(1.3);
      }
      .img_wrapper {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        z-index: 5;
        border-radius: 10px;
        .overlay {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 30%;
          background: transparent;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
      .card_img {
        width: 100%;
        max-width: 100%;
        transform: scale(1);
        transition: 0.5s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
      .patch_wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        cursor: pointer;
        .text_container {
          color: $color-ffffff;
          text-align: left;
          padding: 0 0 1.5em 2.583em;
          font-size: 1.5em;
          .title {
            font-size: 1.333em;
            line-height: 1.281em;
          }
          .desc {
            line-height: 1.708em;
            margin: 0.375em 0 0;
          }
        }
        &:hover .arrow {
          transform: scale(1.3);
        }
        .patch {
          position: relative;
          bottom: -2px;
          right: -2px;
          width: 100%;
          max-width: 12em;
          z-index: 1;
        }
        .arrow {
          width: 100%;
          max-width: 4.344em;
          position: absolute;
          bottom: 2em;
          right: 2em;
          transform: scale(1);
          transition: 0.3s ease-in-out;
          z-index: 1;
        }
      }
      .patch_row {
        align-items: flex-end;
      }
    }
  }
  .subheading {
    margin-bottom: 0.32em;
  }
  .text {
    max-width: 51.399999em;
    margin: 0 auto;
    margin-bottom: 1.8em;
  }
  .location_text {
    color: $color-1C1C1C;
    font-size: 1.5em;
    font-weight: 700;
    // line-height: 4.063rem;
    margin-bottom: 2.0835em;
  }
  .map_img {
    width: 100%;
    max-width: 85.188em;
  }

  // map animation start

  // map animation end
}

.home_sec7 {
  padding: 0 0 5.875em;
  overflow: hidden;
  .text_container {
    padding: 0 3.125em 0 0;
    .desc {
      font-size: 1.25em;
      padding: 1em 0 1.784em;
      color: $color-1B1B1B;
    }
  }
  .news_box {
    .img_wrapper {
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      z-index: 5;
      border-radius: 7.5px;
    }
    .news_img {
      width: 100%;
      max-width: 100%;
      transform: scale(1);
      transition: 0.5s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    .text_container {
      color: $color-1B1B1B;
      padding: 2em 0 0;
      .title {
        font-size: 1.5em;
        line-height: 1.458em;
      }
      font-weight: bold;
      .desc {
        font-size: 1.25em;
        padding: 0.8em 0;
        font-weight: normal;
      }
      .cta_wrapper {
        .cta {
          font-size: 1.125em;
          line-height: 1.944em;
          text-decoration: underline;
          margin-top: 1.2em;
          color: $color-1B1B1B;
          font-weight: 500;
        }
      }
    }
  }
  .arrows_wrapper {
    display: flex;
    align-items: center;
    padding: 2.875em 0 0;
    .newsleft_arrow,
    .newsright_arrow {
      width: 100%;
      max-width: 4.938em;
      height: 4.938em;
      cursor: pointer;
    }
    .newsleft_arrow {
      margin-right: 1.75em;
    }
    .newswhiteleft_arrow,
    .newswhiteright_arrow {
      width: 100%;
      max-width: 4.938em;
      cursor: pointer;
    }
    .newswhiteleft_arrow {
      margin-right: 1.75em;
      transform: rotate(180deg);
    }
  }
}

@media (min-width: 2151px) {
  .home_sec4 {
    .product_row {
      .product_col {
        .product_container {
          min-height: auto;
        }
      }
    }
  }
  .recommend-heading {
    color: #fff;
    font: normal normal 400 18px/35px Poppins;
  }
  .recommend-desc {
    font: normal normal 600 18px/20px Poppins;
    color: #fff;
  }
  .home_sec7 {
    .swiper_wrapper {
      margin: 0 -9em 0 0;
    }
  }
}
@media (min-width: 1920px) {
  .recommend_main_container-1,
  .recommend_main_container-2,
  .recommend_main_container-3 {
    padding-top: 95px;
  }
  .recommend-heading {
    color: #fff;
    font: normal normal 400 21px/35px Poppins;
  }
  .recommend-desc {
    font: normal normal 600 21px/20px Poppins;
    color: #fff;
  }
}

@media (max-width: 1536px) {
  // .home_sec1 {
  //   .banner_sec {
  //     .banner_heading {
  //       line-height: 3.75rem;
  //     }
  //     .banner_sub_heading {
  //       line-height: 1.563rem;
  //     }
  //   }
  // }

  .home_sec2 {
    .quality_row {
      .quality_col {
        .quality_desc {
          line-height: 1.563rem;
        }
      }
    }
  }

  .home_sec4 {
    .product_row {
      .product_col {
        .product_title {
          line-height: 1.563rem;
        }
        .product_desc {
          line-height: 1.563rem;
        }
      }
    }
  }
}

@media (max-width: 1367px) {
  .home_sec1 {
    .banner_sec {
      .details_row {
        .keyfeatures_row {
          margin-left: -1.5em;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .home_sec1 {
    .banner_sec {
      .banner_content_box {
        top: 50%;
        transform: translate(0%, 50%);
      }
    }
  }
  .feature_list li {
    padding-bottom: 1rem;
    font: normal normal normal 12px/27px Poppins;
  }
  .recommend-heading {
    color: #fff;
    font: normal normal 400 11px/25px Poppins;
  }
  .recommend-desc {
    font: normal normal 600 11px/20px Poppins;
    color: #fff;
  }
}
@media (max-width: 1194px) {
  .home_sec1 {
    .banner_sec {
      .banner_img {
        min-height: 100vh;
      }
    }
  }
  .home_sec4 {
    .product_row {
      .product_col {
        .product_container {
          min-height: 13.75em;
        }
      }
    }
  }
  .feature_list li {
    padding-bottom: 1rem;
    font: normal normal normal 12px/27px Poppins;
  }
  .recommend-heading {
    color: #fff;
    font: normal normal 400 11px/25px Poppins;
  }
  .recommend-desc {
    font: normal normal 600 11px/20px Poppins;
    color: #fff;
  }
  .home_sec6 {
    .supplier_products {
      .highlight_card {
        .patch_wrapper {
          .patch {
            max-width: 11em;
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .home_sec1 {
    .banner_sec {
      .banner_img {
        min-height: 70vh;
      }
    }
  }
}

@media (max-width: 1024px) and (max-height: 650px) {
  .home_sec1 {
    .banner_sec {
      .swiper-pagination {
        bottom: 12vh;
      }
    }
  }
  .home_sec2 {
    top: 90%;
  }
  .home_sec3 {
    padding: 31.376em 0 4.75em;
  }
}

@media (max-width: 1024px) {
  .home_sec1 {
    .banner_sec {
      .banner_img {
        object-fit: contain;
        min-height: auto;
      }
      .banner_tio2_text {
        right: 14em;
      }
      .details_row {
        .keyfeatures_row {
          margin-left: -0.5em;
        }
      }
    }
  }

  .home_sec2 {
    position: relative;
    padding: 6.375em 0 0;
  }

  .home_sec3 {
    padding: 6.375em 0 4.75em;
  }
  .feature_list li {
    padding-bottom: 1rem;
    font: normal normal normal 12px/27px Poppins;
  }
  .recommend-heading {
    color: #fff;
    font: normal normal 400 11px/25px Poppins;
  }
  .recommend-desc {
    font: normal normal 600 11px/20px Poppins;
    color: #fff;
  }
}

@media (max-width: 992px) {
  .home_sec1 {
    .banner_sec {
      .banner_heading {
        font-size: 3em;
      }
      .banner_content_box {
        top: 50%;
        -ms-transform: translate(0%, 50%);
        transform: translate(0%, 50%);
      }
      .banner_tio2_text {
        right: 16em;
      }
      .banner_arrows_wrapper {
        left: 6.75em;
        right: 6.75em;
        bottom: 51.188em;
      }
      .swiper-pagination {
        bottom: 27vh;
      }
      .details_row {
        .details_col_content {
          max-width: 100%;
          &:nth-child(1) {
            padding-right: 0;
          }
        }
        .keyfeatures_row {
          margin-left: 0;
        }
        .keyfeature_col_wrapper {
          max-width: 90%;
        }
      }
      //  .banner_tio2_text {
      //   top: 42em;
      //  }
    }
  }

  .home_sec4 {
    .product_row {
      .product_col {
        .product_container {
          min-height: 21.3em;
        }
      }
      .arrows_wrapper {
        position: relative;
        margin-bottom: 2.8em;
      }
    }
  }
}

@media (max-width: 835px) {
  .home_sec1 {
    .banner_sec {
      .banner_arrows_wrapper {
        left: 9.75em;
        right: 9.75em;
        bottom: 45.188em;
      }
      .details_row {
        max-width: 60.648%;
        // .details_col_content {
        //   max-width: 80%;
        // }
        .details_col {
          &:nth-child(1) {
            padding-right: 0;
          }
          &:nth-child(2) {
            padding-left: 0;
          }
        }
        .keyfeature_col_wrapper {
          max-width: 68%;
        }
      }
      .swiper-pagination {
        bottom: 23vh;
      }
    }
  }
  .home_sec2 {
    .quality_row {
      .quality_col_1:nth-child(1) .quality_col {
        margin-top: 0;
      }
      .quality_col {
        padding: 0;
        margin-top: 2.5em;
      }
    }
  }
}

@media (max-width: 833px) and (max-height: 1160px) {
  .home_sec1 {
    .banner_sec {
      .details_row {
        max-width: 66.648%;
        top: 2.458em;
        .keyfeatures_row {
          margin-left: 0;
        }
        .keyfeature_col_wrapper {
          max-width: 100%;
        }
      }
      .banner_arrows_wrapper {
        left: 7.75em;
        right: 7.75em;
      }
      .swiper-pagination {
        bottom: 17vh;
      }
    }
  }
  .home_sec2 {
    top: 85%;
  }
}

@media (max-width: 821px) {
  .home_sec3 {
    padding: 42.376em 0 4.75em;
  }
}

@media (max-width: 768px) {
  .home_sec1 {
    padding-top: 0;
    .banner_sec {
      margin-top: -0.5em;
      .banner_content_box {
        padding: 0 15px;
        top: 35%;
        -ms-transform: translate(0%, 25%);
        transform: translate(0%, 25%);
        text-align: center;
      }
      .mbbanner_img {
        min-height: 100vh;
      }
      .banner_tio2_text {
        top: 8em;
        right: 2em;
        .banner_tio2 {
          font-size: 5.2em;
          line-height: normal;
          padding: 0.76925em 0.42308em;
        }
      }
      .banner_heading {
        font-size: 3.5em;
        line-height: 2.19rem;
        margin-bottom: 0.42857em;
      }
      .banner_sub_heading {
        font-size: 1.6em;
        line-height: 1.5rem;
        margin-bottom: 1.625em;
      }
      .banner_text_block {
        display: inline;
      }
      .cta_wrapper {
        max-width: 16.9em;
        margin: 0 auto;
      }
      .mb_details_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .details_product_img_wrapper {
          width: 100%;
          max-width: 24.8em;
          border: 1px solid #ffffff99;
          padding: 1.3em 0.8em 2em;
          margin-bottom: 2.3em;
        }
        .details_product_img {
          // width: 100%;
          max-width: 23.2em;
        }
        .cta_wrapper {
          margin: 0 auto;
        }
        .details_heading {
          color: $color-E8C339;
          font-size: 2em;
          line-height: 1.6;
          text-align: center;
        }
        .details_subheading {
          font-size: 0.8em;
          color: $color-ffffff;
        }
      }
      .swiper-pagination {
        bottom: 6.7vh;
      }
      .swiper-pagination-bullet {
        max-width: 0.8em;
        height: 0.8em;
      }
    }
  }

  .home_sec2 {
    position: relative;
    top: 0;
    padding: 3.6em 0 0;
    .quality_row {
      padding: 3.1em 4.4em 3.1em 4.3em;
      .quality_col {
        margin-top: 3.2em;
        // padding: 0 2em;
        padding: 0;
        // .quality_img {
        //   max-width: 7em;
        //   margin-bottom: 1.6em;
        // }
        .quality_heading {
          margin: 0.888888em 0 0.4167em;
        }
        // icon animation start
        .socialicon {
          max-width: 7em;
          height: 7em;
          margin-bottom: 1.6em;
        }
        .socialiconcircle2 {
          max-width: 5.75em;
          height: 5.75em;
        }
        .icons {
          max-width: 2.1em;
        }
        // icon animation end

        .quality_heading {
          font-size: 1.8em;
          // line-height: 70px;
          margin-bottom: 0.445em;
        }
        .quality_desc {
          font-size: 1.4em;
          line-height: 1.5rem;
        }
        // &:nth-child(1) {
        //   margin-top: 0;
        // }
        // &:nth-child(2) {
        //   padding: 0;
        // }
      }
      .quality_col_1:nth-child(1) .quality_col {
        margin-top: 0;
      }
    }
  }

  .home_sec3 {
    padding: 3.507em 0 3.621em;
    .about_row {
      text-align: center;
      .text_wrapper {
        padding-left: 0;
      }
      .text {
        margin-bottom: 1.715em;
      }
      .cta_wrapper {
        margin: 0 auto;
        max-width: 16.9em;
      }
    }
    .img_wrapper {
      padding-top: 2.8em;
      .mbvideo_banner {
        width: 100%;
        // max-width: 34.5em;
        max-width: 100%;
      }
      .play_btn {
        max-width: 5.2em;
        top: 54%;
      }
    }
  }

  .home_sec4 {
    text-align: center;
    .subheading {
      margin-bottom: 0.5715em;
    }
    .text {
      margin-bottom: 1.715em;
    }
    .product_row {
      margin-bottom: 3.507em;
      .product_col {
        padding: 0 3em 0 2.9em;
        .product_container {
          min-height: auto;
        }
        .img_wrapper {
          margin-bottom: 2em;
        }
        .product_title {
          font-size: 1.8em;
          line-height: 1.75rem;
          margin-bottom: 0.89em;
        }
        .product_desc {
          font-size: 1.4em;
          line-height: 1.5rem;
          margin-bottom: 1.4286em;
        }
        .product_link {
          font-size: 1.4em;
          line-height: 2.5;
          margin-top: 0;
          margin-bottom: 2.215em;
        }
      }
    }
    .cta_wrapper {
      margin-top: 2.893em;
    }
  }

  .home_sec5 {
    padding: 3.6em 0 3.607em;
    text-align: center;
    .app_row {
      margin-bottom: 0;
      .app_col {
        .text {
          text-align: center;
        }
      }
    }

    .app_swiper {
      margin-bottom: 2.8em;
    }
    .app_wrapper {
      .arrows_wrapper {
        justify-content: center;
        float: none;
        .appleft_arrow {
          margin-right: 2.2em;
        }
      }
      .cta_wrapper {
        margin: 0 auto;
        margin-top: 2.4em;
      }
    }
    .app_slider {
      .app_details {
        left: 0;
        right: 0;
        bottom: 1.593em;
        .app_title {
          font-size: 1.2em;
          // line-height: 2.188rem;
          margin-bottom: 0.505em;
        }
        .app_desc {
          font-size: 1.8em;
          // line-height: 4.375rem;
        }
      }
    }
  }

  .home_sec6 {
    padding: 3.2em 0 2.607em;
    .subheading {
      margin-bottom: 0.5715em;
    }
    .text {
      margin-bottom: 1.715em;
    }
    .location_text {
      font-size: 1.6em;
      line-height: 1.5rem;
      width: 100%;
      max-width: 18em;
      margin: 0 auto;
      margin-bottom: 0.685em;
    }
    .supplier_products {
      .row .col-md-6:first-child,
      .row .col-md-6:last-child {
        padding: 0 15px;
      }
      .highlight_card {
        margin: 0 0 2em;
        .patch_wrapper {
          .text_container {
            padding-left: 15px;
            max-width: 70%;
          }
        }
      }
    }
  }
  .home_sec7 {
    .text_container {
      text-align: center;
      padding: 0;
      .subheading {
        margin-bottom: 0.5715em;
      }
      .text {
        margin-bottom: 1.715em;
      }
      .cta_wrapper {
        margin: 0 auto 2em;
      }
    }
    .arrows_wrapper {
      justify-content: center;
    }
  }
}

// @media (max-width: 391px) {
//   .home_sec4 {
//     .product_row {
//       .product_col {
//         .product_title {
//           .block {
//             display: inline;
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 342px) {
  .home_sec1 {
    .banner_sec {
      .banner_content_box {
        top: 30%;
      }
      .banner_heading {
        font-size: 2.4em;
      }
      .banner_sub_heading {
        font-size: 1.3em;
      }
    }
  }
}
