@import "../../common.scss";

// .about_sec1 {
//   padding-top: 8.813em;
// }

.about_sec2 {
  padding: 6.188em 0 4.875em;
  .subheading {
    margin-bottom: 0.8em;
  }
  .about_row {
    max-width: 100%;
    margin: 0 auto;
    .about_col {
      padding: 0;
    }
    .about_col:nth-child(2n + 3) {
      // padding-right: 3em;
      padding-right: 4.075em;
    }
    .about_col:nth-child(2n + 4) {
      // padding-left: 5.15em;
      padding-left: 4.075em;
    }
  }
}

.about_sec3 {
  background-color: $color-e7c95b1a;
  // padding: 5.375em 0 4.063em;
  // margin-bottom: 12.75em;
  padding: 5.438em 0 4.5em;
  margin-bottom: 18.313em;
  position: relative;
  .subheading {
    margin-left: 0.5em;
  }
  .text {
    margin-bottom: 1.7em;
  }
  .tio_row {
    // position: relative;
    .tio_col {
      padding: 0;
      .tio2_cta {
        display: flex;
        align-items: center;
        .product_cta_wrapper {
          margin-left: 2.375em;
          .about-button {
            border: none;
            text-decoration: underline;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
    .tio_col:nth-child(2n + 1) {
      // padding-right: 5.094em;
      padding-right: 4.075em;
    }
    .tio_col:nth-child(2n + 2) {
      // padding-left: 5.094em;
      padding-left: 4.075em;
    }
    .tio2_img_container {
      position: absolute;
      // top: 36%;
      // left: 0;
      top: 75%;
      right: 0;
      .tio2_img {
        width: 100%;
        // max-width: 62.625em;
        max-width: 65.25em;
        // margin-bottom: 1.69em;
        margin-bottom: 1em;
      }
    }
    .tio_icon_row {
      padding-top: 5.188em;
      margin-left: -2.4em;
      .tio_icon_col {
        text-align: center;
      }
      .tio_icon {
        width: 100%;
        max-width: 5.438em;
      }
      .tio_icon_text {
        color: $color-1C1C1C;
        font-size: 1.25em;
        letter-spacing: 0;
        line-height: 1.6;
        margin-top: 0.55em;
        margin-bottom: 1.85em;
      }
    }
  }
}

.about_sec4 {
  padding: 6em 0 5.125em;
  background-image: url("../../source/images/aboutus/quality_back.png");
  background-repeat: no-repeat;
  background-size: cover;
  .quality_row {
    .quality_col_1,
    .quality_col {
      .quality_img {
        width: 100%;
        max-width: 5.438em;
      }
      .quality_heading {
        font-size: 1.5em;
        line-height: 2.188rem;
        color: $color-E8C339;
        margin: 1.0835em 0 0.75em;
        font-weight: 600;
        user-select: text;
      }
      .text {
        color: $color-ffffff;
        font-weight: 400;
        opacity: 0.8;
        user-select: text;
      }
    }
  }
}

.about_sec5 {
  // background-image: url("../../source/images/aboutus/about_map.png");
  // background-repeat: no-repeat;
  // background-size: contain;
  // padding: 6.194em 0 9.5em;
  padding: 6.194em 0 0;
  .footprint_container {
    background-image: url("../../source/images/aboutus/about_map.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 9.5em;
  }
  .footprint_row {
    margin: 0 auto;
    .footprint_col {
      padding: 0;
    }
    .text {
      margin-bottom: 8.7em;
    }
    .footprint_col:nth-child(2n + 1) {
      padding-right: 4.075em;
    }
    .footprint_col:nth-child(2n + 2) {
      padding-left: 4.075em;
    }
  }

  .location_row {
    .location_col {
      padding: 0;
    }
    .location_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .location_img {
        width: 100%;
        max-width: 6.127em;
      }
      .country {
        font-size: 1.5em;
        // line-height: 2rem;
        line-height: 1.33;
        color: $color-1C1C1C;
        font-weight: 700;
        margin-left: 0.3125em;
      }
    }
  }
}

.about_sec6 {
  .team_wrapper {
    text-align: center;
    width: 100%;
    max-width: 82.625em;
    margin: 0 auto;
    .text {
      margin: 1.2em 0 2.545em;
    }
  }
}

.about_sec7 {
  background-color: $color-e7c95b1a;
  padding: 4.813em 0 4.75em;
  margin-bottom: 6.868em;
  .team_slider_row {
    position: relative;
    padding: 0 4.25em;
    // margin: 0 1.75em;
    .team_slide_col {
      padding: 3.313em 3.438em;
      background-color: $color-ffffff;
      text-align: center;
      .person_img {
        width: 100%;
        max-width: 2.628em;
      }
      .team_name {
        font-size: 1.75em;
        line-height: 2.375rem;
        color: $color-1B1B1B;
        font-weight: 700;
        margin: 0.858em 0 0.35em;
      }
      .text {
        font-weight: 400;
      }
    }
    .team_arrow {
      .team_left_arrow,
      .team_right_arrow {
        position: absolute;
        width: 100%;
        max-width: 4.938em;
        height: 100%;
        max-height: 4.938em;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .team_left_arrow {
        left: -2.5em;
      }
      .team_right_arrow {
        right: -2.5em;
      }
    }
  }
}

@media (max-width: 1193px) {
  .about_sec7 {
    .team_slider_row {
      .team_slide_col {
        padding: 3.313em 1.438em;
      }
    }
  }
}

@media (max-width: 1080px) {
  .about_sec3 {
    .tio_row {
      .tio2_img_container {
        top: 83%;
        // left: -3%;
        right: -3%;
        .tio2_img {
          max-width: 56.625em;
        }
      }
    }
  }
  .about_sec7 {
    .team_slider_row {
      .team_slide_col {
        padding: 3.313em 3.438em;
      }
    }
  }
}

@media (max-width: 992px) {
  .about_sec3 {
    .tio_row {
      .tio2_img_container {
        top: 99%;
        // left: -14%;
        right: -14%;
        .tio2_img {
          max-width: 51.625em;
        }
      }
    }
  }
  .about_sec4 {
    .mbarrows_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3em;
      .left_arrow,
      .right_arrow {
        width: 100%;
        max-width: 5em;
      }
      .left_arrow {
        margin-right: 2.2em;
      }
    }
  }

  .about_sec5 {
    .location_col {
      margin-bottom: 3em;
    }
  }
  .about_sec7 {
    .team_slider_row {
      .team_slide_col {
        padding: 3.313em 1.438em;
      }
    }
  }
}

@media (max-width: 835px) {
  .about_sec3 {
    .tio_row {
      .tio2_img_container {
        top: 69%;
        // left: -6%;
        right: -6%;
        .tio2_img {
          max-width: 50.625em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  // .about_sec1 {
  //   padding-top: 8em;
  // }

  .about_sec2 {
    padding: 4.207em 0 0.4em;
    text-align: center;
    .subheading {
      margin-bottom: 0.569em;
    }
    .text {
      opacity: 0.8;
    }
    .about_row {
      max-width: 100%;
      margin: 0 auto;
      .about_col:nth-child(2n + 3) {
        padding-right: 0;
      }
      .about_col:nth-child(2n + 4) {
        padding-left: 0;
        margin-top: 1.143em;
      }
    }
  }

  .about_sec3 {
    padding: 1.5em 0 2.6em;
    margin-bottom: 0;
    text-align: center;
    .subheading {
      margin-left: 0;
    }
    .text {
      margin-bottom: 2.215em;
    }
    .tio_row {
      max-width: 100%;
      margin: 0 auto;
      .tio_col {
        .tio2_cta {
          justify-content: center;
        }
      }
      .tio_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .tio_col:nth-child(2n + 2) {
        padding-left: 0;
      }
      .tio2_img_container {
        position: relative;
        top: initial;
        // left: initial;
        right: initial;
        .tio2_img {
          max-width: 37.5em;
          margin-bottom: 3.1em;
        }
      }
      .tio_icon_row {
        margin: 0 auto;
        // .tio_icon_col {
        //   padding: 0;
        // }
        .tio_icon {
          max-width: 4.1em;
        }
        .tio_icon_text {
          font-size: 1.4em;
          line-height: 1.5;
          margin-top: 0.5715em;
          margin-bottom: 3.14288em;
        }
      }
    }
  
  }

  .about_sec4 {
    background-image: url("../../source/images/aboutus/mb_quality.png");
    // background-color: #1b1b1b;
    background-color: #272626;
    background-size: cover;
    padding: 4em 0 4.9em;
    .quality_row {
      .quality_col_1,
      .quality_col {
        padding: 0 4.25em 0 4.25em;
        text-align: center;
        .quality_heading {
          font-size: 1.8em;
          line-height: 2.188rem;
          margin: 0.89em 0 0.445em;
        }
        .quality_img {
          max-width: 4.7em;
        }
        .text {
          margin-bottom: 3.072em;
        }
      }
      .mbarrows_wrapper {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-top: 0;
        .left_arrow,
        .right_arrow {
          width: 100%;
          max-width: 5em;
          height: 100%;
          max-height: 5em;
        }
        .left_arrow {
          margin-right: 2.2em;
        }
      }
    }
  }

  .about_sec5 {
    padding: 3.2em 0 0;
    .footprint_container {
      padding-bottom: 5.6em;
    }
    .footprint_row {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      .footprint_col:nth-child(2n + 1) {
        padding-right: 0;
      }
      .footprint_col:nth-child(2n + 2) {
        padding-left: 0;
      }
      .text {
        margin-bottom: 3.645em;
      }
    }
    .location_col {
      margin-bottom: 3.6em;
    }
    .location_row {
      .location_wrapper {
        .country {
          font-size: 1.6em;
          line-height: 1.625;
        }
      }
    }
  }

  .about_sec6 {
    .team_wrapper {
      .text {
        margin: 1.143em 0 1.7em;
      }
    }
  }

  .about_sec7 {
    padding: 5.6em 0;
    margin-bottom: 3.592em;
    .team_slider_row {
      padding: 0 6.45em;
      .team_slide_col {
        padding: 2.407em 2.407em;
      }
      .team_slide_col {
        .person_img {
          max-width: 2.033em;
        }
        .team_name {
          font-size: 1.8em;
          line-height: 1.75rem;
        }
        .text {
          margin-bottom: 0;
        }
      }
      .team_arrow {
        .team_right_arrow {
          right: 0;
        }
        .team_left_arrow {
          left: 0;
        }
      }
    }
  }
}
