@import "../../common.scss";

.tableold_sec1 {
  // padding-bottom: 7.528em;
  padding-bottom: 5.96em;
  .table_section {
    overflow-x: auto;
  }

  .table_content {
    width: 100%;
    max-width: 100%;
    text-align: center;
    th,
    td {
      color: $color-1C1C1C;
      font-size: 1.25em;
      line-height: 1.875rem;
      width: 14.28%;
      padding: 0;
      height: 5.55em;
      // padding: 0 1.656em;
    }
    th {
      font-weight: 600;
    }
    td {
      font-weight: 400;
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .full_star {
      width: 100%;
      max-width: 2.361em;
    }
    .star {
      width: 100%;
      max-width: 2.5782em;
    }
  }
  .table_heading th {
    border: 1px solid #70707047;
    // padding: 0 1.656em;
    padding: 0 2em;
    text-align: center;
    text-transform: capitalize;
  }
  .table_heading .heading {
    background-color: $color-E7C95B;
  }

  .table_content {
    .table_row1 {
      td {
        p {
          background-color: $color-E8C3391A;
        }
      }
    }
    .table_row1,
    .table_row2 {
      th {
        text-transform: uppercase;
        p {
          background-color: #e7c95b;
        }
      }
    }
    .table_row1,
    .table_row2 {
      p {
        margin-bottom: 0.5em;
        // border-top: 1px solid #70707047;
        border-bottom: 1px solid #70707047;
        border-right: 1px solid #70707047;
        // height: 6.9377em;
        height: 5.55em;
        padding: 0 2em;
      }
    }
    // .table_row2:last-child p {
    //   margin-bottom: 2.25em;
    // }
    .table_row2 {
      p {
        border-top: 1px solid #70707047;
      }
    }
  }
  .table_row1,
  .table_row2 {
    .left_border {
      border-left: 1px solid #70707047;
    }
  }

  .table_title {
    background-color: #2a2a2a0d;
    color: $color-1C1C1C;
    font-size: 1.5em;
    font-weight: 600;
    // line-height: 4.063rem;
    line-height: 3.625rem;
    text-align: center;
    margin-bottom: 1.625em;
    // padding: 0.781em 0;
  }

  .table_index_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // padding-top: 3.297em;
    padding-top: 2.672em;
    .table_index {
      width: 100%;
      max-width: 20%;
      .index_container {
        display: flex;
        align-items: center;
        .index_img1 {
          width: 100%;
          max-width: 2.951em;
        }
        .index_img2 {
          width: 100%;
          max-width: 3.094em;
        }
        .index_name {
          margin-left: 0.469em;
          font-size: 1.25em;
          line-height: 1.875rem;
          color: $color-1C1C1C;
          font-weight: 600;
        }
      }

      &:nth-child(2) {
        margin-left: -8.5em;
      }
    }
  }
}

@media (max-width: 1536px) {
  .tableold_sec1 {
    .table_content {
      th,
      td {
        line-height: 1.25rem;
      }
    }
  }
}

@media (max-width: 1024px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 22%;
      }
    }
  }
}

@media (max-width: 992px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 28.5%;
      }
    }
  }
}

@media (max-width: 767px) {
  .tableold_sec1 {
    padding-bottom: 3.592em;
    .table_title {
      font-size: 1.6em;
      line-height: 2.375rem;
      margin-bottom: 1.25em;
    }
    .table_content {
      th,
      td {
        font-size: 1.4em;
      }
      .full_star,
      .star {
        max-width: 1.803em;
      }
      .table_row2:last-child p {
        margin-bottom: 2.25em;
      }
    }
    .table_index_wrapper {
      padding-top: 2em;
      // justify-content: space-between;
      margin: 0 auto;
      .table_index {
        max-width: 30%;
      }
      .table_index {
        .index_container {
          .index_name {
            font-size: 1.4em;
            margin-left: 0.6em;
          }
          .index_img1,
          .index_img2 {
            max-width: 2.542em;
          }
        }
      }
    }
    .table_index:nth-child(2) {
      margin-left: 0;
    }
  }
}

@media (max-width: 670px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 37%;
      }
    }
  }
}

@media (max-width: 550px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 47%;
      }
    }
  }
}

@media (max-width: 440px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 52%;
      }
    }
  }
}

@media (max-width: 400px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 90%;
      }
    }
  }
}

@media (max-width: 330px) {
  .tableold_sec1 {
    .table_index_wrapper {
      .table_index {
        max-width: 100%;
      }
    }
  }
}
