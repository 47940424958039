@import "../../common.scss";

.contact_sec1 {
  .common_banner_wrapper {
    .breadcrumb_content_wrapper {
      .breadcrumb_text {
        color: $color-1B1B1B;
      }
    }
    .banner_heading {
      color: $color-1C1C1C;
    }
  }
}

.contact_sec2 {
  padding: 2.813em 0 5em;
  .contact_title {
    color: $color-1C1C1C;
    font-size: 2.625em;
    font-weight: 600;
    line-height: 4.063rem;
    text-align: center;
    margin-bottom: 1.04769em;
  }
  .contact_col {
    color: $color-1B1B1B;
    text-align: center;
    position: relative;
    max-width: 30%;
    .contact_icon {
      width: 100%;
      max-width: 7.625em;
      margin-bottom: 1em;
    }
    .contact_heading {
      font-size: 1.75em;
      font-weight: 600;
      line-height: 3.125rem;
    }
    .contact_details {
      color: $color-1B1B1B;
      font-size: 1.25em;
      font-weight: 400;
      line-height: 2.188rem;
      a {
        color: inherit;
      }
    }
    &::after {
      position: absolute;
      content: "";
      height: 80%;
      background-color: #707070;
      opacity: 0.5;
      width: 1px;
      top: 5%;
      right: 0;
    }
    &:last-child::after {
      display: none;
    }
    &:nth-child(2) {
        max-width: 40%;
        flex: 0 0 40%;
    }
    &:first-child {
        padding-right: 6em;
    }
    &:last-child {
        padding-left: 6em;
    }
  }
}

.contact_sec3 {
  .map_img {
    width: 100%;
    max-width: 100%;
    // max-width: 102.5em;
  }
}

.contact_sec4 {
  padding: 5em 0 6.313em;
  .details_col {
    .left_heading {
      font-weight: 600;
      font-size: 2.625em;
      line-height: 4.063rem;
      margin-bottom: 0.28577em;
    }
    .left_content {
      font-size: 1.25em;
      line-height: 2.188rem;
    }
    &:nth-child(2n + 1) {
      padding-right: 6.364em;
      max-width: 32.318%;
    }
    .contact_row2 {
      justify-content: space-between;
      align-items: flex-end;
      .contact_col2 {
        position: relative;
        font-size: 1.25em;
        max-width: 48.477%;
        max-width: 47.5%;
        margin-bottom: 3.31em;
        .contact_input {
          // height: calc(2.5em + 2px);
          padding-left: 0;
          padding-right: 0;
        }
        // .contact_input:focus {
        //   padding-top: 0.625em;
        // }
        &:last-child {
          margin-bottom: 0;
        }
        // &:nth-last-child(2) {
        //   margin-bottom: 0;
        // }
        .contact_catergory {
            appearance: none;
            -webkit-appearance: none;
            background-color: $color-ffffff;
            background-image: url("../../source/images/contactus/dropDown_arrow.png");
            background-repeat: no-repeat;
            background-position: calc(100% - 0.75em) center;
            background-size: 1.113em;
            font-size: 1em;
            width: 100%;
            max-width: 100%;
            border: none;
            border-bottom: 1px solid #c3bebe;
            // padding: 1em .75em;
            padding: 1rem 0;
        }
        .contact_msg {
            resize: none;
            overflow: hidden;
            // padding: 0.5rem 0.75rem 1rem;
        }
      }
      .form-floating > .form-control:focus ~ label {
        transform: scale(0.85) translateY(-1.5em) translateX(0.15em);
      }
      .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.85) translateY(-1em) translateX(.15em);
      }
      .form-floating>label {
        top: -10%;
      }
      .cta_wrapper {
        max-width: 13.55em;
        .about-button {
          height: 3.615em;
          font-size: 0.9em;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 2536px) {

}

@media (max-width: 1536px) {
  .contact_sec2 {
    .contact_col {
      .contact_details {
        line-height: 1.5rem;
      }
    }
  }

  .contact_sec4 {
    .details_col {
      .left_content {
        line-height: 1.5rem;
      }
      .contact_row2 {
        .contact_col2 {
          .contact_input {
            height: calc(2.5em + 2px);
          }
          .contact_catergory {
            padding: 0.5em 0;
           }
        }
        .form-floating > label {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .contact_sec4 {
    .details_col:nth-child(2n + 1) {
      padding-right: 12px;
      max-width: 100%;
    }
    .details_col {
      max-width: 100%;
      flex: 0 0 100%;
      .left_content {
        margin-bottom: 2em;
      }
      .contact_row2 {
        .contact_col2 {
          .contact_input {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
    
  }
}

@media (max-width: 767px) {
  .contact_sec2 {
    padding: 3.2em 0 4.783em;
    .contact_title {
      font-size: 2.8em;
      line-height: 1.75rem;
      margin-bottom: 1em;
    }
    .contact_col {
        max-width: 100%;
      margin-bottom: 3.1em;
      
      &:last-child {
        margin-bottom: 0;
      }
      .contact_icon {
        max-width: 5.6em;
        margin-bottom: 0;
      }
      .contact_heading {
        font-size: 1.8em;
      }
      .contact_details {
        font-size: 1.4em;
        line-height: 1.563rem;
      }
      &:nth-child(2) {
        max-width: 100%;
        flex: 0 0 100%;
      }
      &:first-child {
        padding-right: 12px;
      }
      &:last-child {
        padding-left: 12px;
      }
      &::after {
        display: none;
      }
    }
  }

  .contact_sec3 {
    .map_img {
      max-width: 34.3em;
      height: 100%;
      min-height: 25.3em;
    }
  }

  .contact_sec4 {
    padding: 4.2em 0 3.213em;
    .details_col {
      .left_heading {
        font-size: 2.8em;
        // line-height: 4.438rem;
        line-height: 2.438rem;
        margin-bottom: 0.5358em;
        text-align: center;
      }
      .left_content {
        font-size: 1.4em;
        line-height: 1.313rem;
        margin-bottom: 3em;
        text-align: center;
      }
      .contact_row2 {
        align-items: center;
        .contact_col2 {
          max-width: 100%;
          // margin-bottom: 1.2em;
          margin-bottom: 1.5em;
          .contact_input {
            padding: 0.742855em 0;
            line-height: 1.313rem;
            height: calc(2.75rem + -2px);
          }
          // .form-floating > .form-control:focus ~ label {
          //   transform: scale(0.85) translateY(-1em) translateX(0.15em);
          // }
          &:nth-last-child(2) {
            margin-bottom: 2.24em;
          }
          .contact_catergory {
            background-size: 1.19em;
            // padding: 1.04em 0;
            padding: 0;
          }
        }
        .form-floating>.form-control:focus~label {
          transform: scale(0.85) translateY(-1.5em) translateX(0.15em);
        }
        .form-floating > label {
          top: -10%;
        }
        .form-floating>.form-control:not(:placeholder-shown)~label {
          transform: scale(.85) translateY(-1.5em) translateX(0.15em);
        }
        .cta_wrapper {
          max-width: 13.52em;
          float: initial;
          margin: 0 auto;
          .about-button {
            font-size: 1.12em;
            height: 3.215em;
          }
        }
      }
    }
  }
}
