$color-ffffff: #ffffff;
$color-1B1B1B: #1b1b1b;
$color-E7C95B: #e7c95b;
$color-E8C339: #e8c339; //new color
$color-0000001C: #0000001c;
$color-C2A877: #c2a877;
$color-1C1C1C: #1c1c1c;
$color-2A2A2A: #2a2a2a;
$color-707070: #707070;
$color-000000: #000000;
$color-E1E1E1: #e1e1e1;
$color-e7c95b1a: #e7c95b1a;
$color-E8C3391A: #e8c3391a;
$color-C2A877: #c2a877;
$color-323232: #323232;
$color-020202: #020202;
$color-E8E8E8: #e8e8e8;
$container-2560: 2226px;
$container-1920: 1650px;
$container-1700: 1619px;
$container-1620: 1480px;
$container-1536: 1356px;
$container-1440: 1200px;
$container-1366: 1194px;
$container-1280: 1113px;
$container-1194: 1031px;
$container-1080: 931px;
$container-992: 700px;

@mixin cta {
  background-color: $color-E8C339;
  border: none;
  border-radius: 50px;
  color: $color-1B1B1B;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 1.125em;
  line-height: 35px;
  font-weight: 500;
  height: 3.6112em;
  overflow: hidden;
  // padding-left: 1.781em;
  // position: relative;
  text-align: center;
  transition: all 0.3s;
  width: 100%;
  z-index: 2;
}

body {
  font-family: "Poppins", sans-serif;
}
img {
  object-fit: cover;
  height: auto;
}
::-webkit-scrollbar {
  // background-color: $color-ffffff;
  background-color: #d5d5d5;
  width: 6px;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background-color: #545353;
}

::-webkit-scrollbar-track {
  background-color: #d5d5d5;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

li {
  list-style-type: none;
}

table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cta_wrapper {
  width: 100%;
  max-width: 16.938em;
}

.common_cta {
  @include cta;
}

.common_cta:hover {
  // box-shadow: 0 10px 23px rgba(0, 0, 0, 0.16);
  background-color: transparent;
  border: 2px solid $color-C2A877;
  color: $color-ffffff;
  text-decoration: none;
}

.about-button {
  @include cta;
  background-color: transparent;
  border: 1px solid $color-C2A877;
  &:hover {
    color: $color-1B1B1B;
    background-color: $color-E8C339;
    border: none;
    // box-shadow: inset 20em 0 0 0 $color-E8C339;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .common_cta,
  .about-button {
    font-size: 1.4em;
    // font-weight: 600;
    line-height: 21px;
    height: 3.215em;
  }
}

.sticky_wrapper {
  position: fixed;
  right: 0;
  // top: 15.438em;
  top: 24.25em;
  z-index: 10;
  transform: rotate(180deg);
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  .sticky_text {
    background-color: $color-E8C339;
    color: $color-1B1B1B;
    font-family: "Poppins";
    font-size: 1.125em;
    line-height: 35px;
    padding: 2.055555em 0.806em 1.61111em;
    text-align: center;
    font-weight: 500;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
  }
}

@media (max-width: 1536px) {
  .sticky_wrapper {
    .sticky_text {
      padding: 2.055555em 0.5em 1.61111em;
    }
  }
}

.heading {
  color: $color-C2A877;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 2.188rem;
  text-transform: uppercase;
  margin-bottom: 0.7em;
}
.subheading {
  font-size: 3.125em;
  font-weight: 600;
  color: $color-1C1C1C;
  line-height: 4.063rem;
}
.text {
  color: $color-1B1B1B;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 2.375rem;
  // margin-bottom: 1.6em;
}

.contact_input {
  border: none;
  border-bottom: 1px solid #c3bebe;
  border-radius: 0;
  font-size: 1.25em;
  color: $color-1B1B1B;
}

.contact_input:focus {
  box-shadow: none;
  border-color: #c3bebe;
}

@media (min-width: 2561px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: $container-2560;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: $container-2560;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: $container-1920;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1700px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: $container-1700;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1620px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: $container-1620;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
  .my_container {
    max-width: $container-1536;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .heading {
    line-height: 1.563rem;
  }
  .subheading {
    line-height: 2.813rem;
  }
  .text {
    line-height: 1.75rem;
  }
}
@media (max-width: 1440px) {
  .my_container {
    max-width: $container-1440;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1366;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1280;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1194;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1080px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1080;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 992px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-992;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .firstsec {
    padding-top: 6.8em;
  }
}

@media (max-width: 768px) {
  .form_field {
    .radio_flex {
      flex-wrap: wrap;
      .radio_wrap {
        width: 100%;
        max-width: 50%;
      }
    }
  }
  .Toastify__toast-body {
    font-size: 1.6em;
  }
}
@media (max-width: 767px) {
  .my_container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .heading {
    font-size: 1.4em;
    font-weight: 500;
    line-height: 2.188rem;
    margin-bottom: 0.5715em;
  }
  .subheading {
    font-size: 2.8em;
    line-height: 2.375rem;
    margin-bottom: 0.5715em;
  }
  .text {
    font-size: 1.4em;
    line-height: 1.5rem;
    margin-bottom: 1.715em;
  }
  .sticky_wrapper {
    transform: rotate(0deg);
    writing-mode: initial;
    top: auto;
    right: initial;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    .sticky_text {
      font-size: 1.4em;
      font-weight: 600;
      line-height: 1.313rem;
      padding: 0.786em 0;
      text-transform: inherit;
    }
  }
  .contact_input {
    font-size: 1.4em;
    line-height: 2.188rem;
    // padding: 0.732em 0.732em;
    padding: 0.742855em 0.742855em;
  }
}

.error {
  color: red;
  position: absolute;
  top: 100%;
  left: inherit;
}

// icon animation start

// .socialicons {
//   width: 100%;
//   max-width: 100%;
//   margin: 0 auto;
//   overflow: auto;
//   // display: flex;
//   // justify-content: center;
// }

// .socialicon {
//   width: 100%;
//   max-width: 5.438em;
//   height: 5.438em;
//   border-radius: 50%;
//   overflow: hidden;
//   background-color: $color-E8C339;
//   position: relative;
//   margin-bottom: 1.563em;
//   border: 1px solid $color-1B1B1B;
// }

// .socialiconcircle1 {
//   width: 100%;
//   max-width: 5.438em;
//   height: 5.438em;
//   position: absolute;
//   left: 0px;
//   top: 100px;
//   -webkit-animation: fill 2s infinite;
//   -moz-animation: fill 2s infinite;
//   animation: fill 2s infinite;
// }

// .socialiconcircle2 {
//   width: 100%;
//   max-width: 5.338em;
//   height: 5.338em;
//   border-radius: 50%;
//   background-color: $color-E8C339;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   border: 1px solid $color-1B1B1B;
// }

// .icons {
//   // font-size:2em;
//   width: 100%;
//   max-width: 2.5em;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   -webkit-animation: iconjump 2s infinite;
//   -moz-animation: iconjump 2s infinite;
//   animation: iconjump 2s infinite;
// }

/* fill color */
// .dribbblecolor {
//   background-color: $color-ffffff;
// }

/* fill background */
// @-webkit-keyframes fill {
//   0% {
//     top: 100%;
//   }
//   100% {
//     top: 0%;
//   }
// }

// @-moz-keyframes fill {
//   0% {
//     top: 100%;
//   }
//   100% {
//     top: 0%;
//   }
// }

// @keyframes fill {
//   0% {
//     top: 100%;
//   }
//   100% {
//     top: 0%;
//   }
// }

/* jump social icons */
// @-webkit-keyframes iconjump {
//   0% {
//     top: 50%;
//   }
//   10% {
//     top: 85%;
//   }
//   20% {
//     top: 50%;
//   }
//   50% {
//     top: 50%;
//   }
//   90% {
//     top: 50%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @-moz-keyframes iconjump {
//   0% {
//     top: 50%;
//   }
//   10% {
//     top: 85%;
//   }
//   20% {
//     top: 50%;
//   }
//   50% {
//     top: 50%;
//   }
//   90% {
//     top: 50%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @keyframes iconjump {
//   0% {
//     top: 50%;
//   }
//   10% {
//     top: 85%;
//   }
//   20% {
//     top: 50%;
//   }
//   50% {
//     top: 50%;
//   }
//   90% {
//     top: 50%;
//   }
//   100% {
//     top: 50%;
//   }
// }

// @media (max-width: 767px) {
//   .socialicon {
//     max-width: 7em;
//     height: 7em;
//     margin-bottom: 1.6em;
//   }
//   .socialiconcircle2 {
//     max-width: 5.75em;
//     height: 5.75em;
//   }
//   .icons {
//     max-width: 2.1em;
//   }
// }

// new animation start
.icon_animation {
  display: inline-block;
  width: 100%;
  max-width: 3.118em;
  height: 3.118em;
  border: 1px solid $color-1B1B1B;
  // line-height: 68px;
  line-height: 2em;
  // text-align: center;
  // color: #fff;
  // font-size: 34px;
  font-size: 2.125em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  margin: 0.4em 0 0.17em;
}

.animation_img {
  width: 100%;
  max-width: 1.15em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon_animation:before {
  content: "";
  position: absolute;
  top: -0.377em;
  left: -0.377em;
  bottom: -0.377em;
  right: -0.377em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid $color-1B1B1B;
  opacity: 0.5;
}

.icon_animation:hover {
  background: $color-E8C339;
  // color: #fff;
}
@keyframes bordermove {
  0% {
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }

  100% {
    top: -0.377em;
    left: -0.377em;
    bottom: -0.377em;
    right: -0.377em;
  }
}

.icon_animation:hover:before {
  background: rgba(255, 255, 255, 0.05);
  animation-name: bordermove;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@media (max-width: 767px) {
  .icon_animation {
    max-width: 2.75em;
    height: 2.75em;
  }
  .animation_img {
    max-width: 0.95em;
  }
  .icon_animation:before {
    top: -0.31em;
    left: -0.31em;
    bottom: -0.31em;
    right: -0.31em;
  }
}
// new animation end

// icon animation end

// banner text animation start
.text_animation_down {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.3s;
}
@keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}

.text_animation_up {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.3s;
}
@keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    opacity: 1;
    transform: translateZ(0);
  }
}
// banner text animation end
