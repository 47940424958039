@import "../../common.scss";

.thank_sec1 {
  padding-top: 8.813em;
  .thank_row {
    margin: 0 auto;
    gap: 2.15em;
    .thank_col {
      padding: 0;
      align-self: center;
      .thank_img {
        width: 100%;
        // max-width: 64em;
        max-width: 100%;
      }
      .thank_text_wrapper {
        color: $color-1B1B1B;
        text-align: center;
        .thank_heading {
          font-size: 7.125em;
          font-weight: 600;
          //   line-height: 39.813rem;
          line-height: 10.813rem;
          text-transform: uppercase;
        }
        .thank_text {
          font-size: 1.125em;
          font-weight: 600;
          line-height: 2rem;
          letter-spacing: 0.9px;
          margin-bottom: 1.555555em;
          text-transform: uppercase;
        }
        .cta_wrapper {
          max-width: 19.5em;
          margin: 0 auto;
          .common_cta {
            font-weight: 600;
          }
          .common_cta:hover {
            color: $color-1B1B1B;
          }
        }
      }
      &:nth-child(2n + 1) {
        // max-width: 55.5%;
        max-width: 53.333333%;
      }
    }
  }
}

@media (max-width: 1536px) {
  .thank_sec1 {
    padding-top: 8.813em;
    .thank_row {
      .thank_col {
        .thank_text_wrapper {
          .thank_heading {
            line-height: 6.813rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .thank_sec1 {
    .thank_row {
      .thank_col {
        .thank_text_wrapper {
          .thank_heading {
            line-height: 5.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .thank_sec1 {
    padding-top: 7.5em;
    height: 100vh;
    .thank_row {
      gap: 0;
      .thank_col {
        padding: 0;
        height: 50vh;
        .mbthank_img {
            width: 100%;
          max-width: 100%;
          // max-width: 37.5em;
        }
        .thank_text_wrapper {
          padding: 0 4.7em;
          // margin: 13.4em 0 13.3em;
          transform: translateY(25%);
          .thank_heading {
            font-size: 2.8em;
            // line-height: 11rem;
            line-height: 2rem;
          }
          .thank_text {
            font-size: 1.4em;
            line-height: 1.5rem;
            margin: 0.837em 0 1.377em;
            letter-spacing: 0.7px;
          }
          .cta_wrapper {
            max-width: 28.1em;
          }
        }
      }
      .thank_col:nth-child(2n + 1) {
        max-width: 100%;
      }
    }
  }
}
