@import "../../common.scss";

.not_found_sec1 {
  padding-top: 8.813em;
  .not_found_row {
    margin: 0 auto;
    gap: 2.35em;
    .not_found_col {
        padding: 0;
      align-self: center;
      .not_found_img {
        width: 100%;
        // max-width: 64em;
        max-width: 100%;
      }
      .not_found_text_wrapper {
        color: $color-1B1B1B;
        text-align: center;
        .not_found_heading {
          font-size: 15.813em;
          font-weight: 600;
          line-height: 18.438rem;
          text-transform: uppercase;
        }
        .not_found_text {
          font-size: 1.125em;
          font-weight: 700;
          line-height: 2rem;
          letter-spacing: 0.9px;
          margin-bottom: 1.805555em;
          text-transform: uppercase;
        }
        .cta_wrapper {
            max-width: 19.5em;
          margin: 0 auto;
          .common_cta {
            font-weight: 600;
          }
          .common_cta:hover {
            color: $color-1B1B1B;
          }
        }
      }
      &:nth-child(2n + 1) {
        max-width: 52.8266%;
      }
    }
  }
}

@media (max-width: 1536px) {
  .not_found_sec1 {
    padding-top: 8.813em;
    .not_found_row {
      .not_found_col {
        .not_found_text_wrapper {
          .not_found_heading {
            line-height: 12.813rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .not_found_sec1 {
    .not_found_row {
      .not_found_col {
        .not_found_text_wrapper {
          .not_found_heading {
            line-height: 10.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .not_found_sec1 {
    padding-top: 7.5em;
    height: 100vh;
    .not_found_row {
        gap: 0;
      .not_found_col {
        padding: 0;
        height: 50vh;
        .mbnot_found_img {
            width: 100%;
          max-width: 100%;
          // max-width: 37.5em;
        }
        .not_found_text_wrapper {
            padding: 0 4.7em;
            // margin: 6.9em 0 14.7em;
            transform: translateY(5%);
          .not_found_heading {
            font-size: 11.4em;
            line-height: 6rem;
          }
          .not_found_text {
            font-size: 1.4em;
            line-height: 1.5rem;
            margin: 0.837em 0 1.377em;
            letter-spacing: 0.7px;
          }
          .cta_wrapper {
            max-width: 28.1em;
          }
        }
      }
      .not_found_col:nth-child(2n + 1) {
        max-width: 100%;
      }
    }
  }
}
