@import "../../common.scss";

.header_sec {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-ffffff;
  z-index: 9;
  padding: 2.194em 0;
  border-bottom: 1px solid $color-E1E1E1;
  .navlinks {
    position: relative;
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 50%;
      float: right;
      .logo_wrapper {
        position: absolute;
        top: -1em;
        bottom: 0;
        margin: auto;
        // left: -3.15em;
        left: 0;
        .logo_img {
          position: relative;
          // max-width: 18.458em;
          max-width: 12.125em;
        }
      }
      .nav_tab {
        position: relative;
        color: $color-1B1B1B;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        margin: 0 0 0 3.313em;
        // a {
          // font-size: 1.25em;
          font-size: 1.375em;
          font-weight: 400;
          line-height: 70px;
          color: $color-1B1B1B;
          position: relative;
          // padding: 2.9em 0 2.75em;
          // padding: 2.5em 0 2.5em;
        // }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background-color: $color-C2A877;
          left: 0;
          bottom: -3px;
          position: absolute;
        }
        &:hover {
          text-decoration: none;
          font-weight: 600;
          transition: 0.3s ease;
          // &::after {
          //   width: 2.5em;
          //   transition: 0.3s ease;
          // }
        }
      }
      .nav_tab.active {
        font-weight: 600;
        &::after {
          width: 2.5em;
        }
      }
    }
  }
}

.nav_line2_wrapper_flex_wrapper {
  position: fixed;
  top: 0;
  left: -100%;
  background-color: rgba($color: $color-ffffff, $alpha: 1);
  // background-color: $color-020202;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s left;
  &.active {
    left: 0;
  }
  .logo_wrapper {
    position: absolute;
    max-height: 10vh;
    // top: 1em;
    top: 1.2em;
    margin: auto;
    // left: -0.6em;
    left: 0;
    z-index: 9;
    .atlas_logo {
      width: 100%;
      margin-left: 1.5em;
      max-width: 16.2em;
      padding: 2em 0;
    }
  }
  .nav_social_media_icons {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: left;
    padding-left: 1.5em;

    .nav_social_icons {
      display: none;
      width: 100%;
      max-width: 2em;
      height: 2em;
      object-fit: cover;
      margin: 0 2em 0 0;
    }
    a:last-child {
      .nav_social_icons {
        margin-right: 0;
      }
    }
    .follow_us_text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1.6em;
      text-align: center;
      margin-top: 1.5em;
      a {
        color: $color-000000;
      }
    }
  }
}

.nav_line2_wrapper_flex {
  background-color: $color-020202;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // height: 80%;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  // margin: 10.1em 1.5em 0;
  margin: 8em 0 0;
  padding: 20.7em 1.5em 0;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $color-000000;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-000000;
  }
  .nav_item {
    text-align: left;
    width: 100%;
    position: relative;
    cursor: pointer;
    margin-left: 0;
  }
  .nav_link_wrapper {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }
  .nav_link {
    cursor: pointer;
    color: $color-ffffff;
    text-transform: capitalize;
    font-size: 1.8em;
    font-weight: 400;
    line-height: 4.375rem;
    // padding: 20px 0;
    opacity: 0.8;
    display: block;
    text-align: left;
    // border-bottom: 1px solid #E8E8E8;
    border-bottom: 1px solid #707070;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .not_active_arrow {
      display: block;
      width: 100%;
      max-width: 1.027em;
      object-fit: contain;
    }
    .active_arrow {
      display: none;
    }
  }
  .nav_link.active {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: $color-C2A877;
    border-bottom: 1px solid #707070;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: -0.4em;
    //   left: 0;
    //   background-color: $color-212529;
    //   height: 2.2px;
    //   width: 2em;
    // }
    .not_active_arrow {
      display: none;
    }
    .active_arrow {
      display: block;
      width: 100%;
      max-width: 1.027em;
      object-fit: contain;
    }
  }
  .nav_linkbtn {
    color: #ffffff;
    border: none;
    border-radius: 10px;
    background: transparent linear-gradient(90deg, #b20710 0%, #ee2c3c 100%) 0%
      0% no-repeat padding-box;
    padding: 0.75em 1.25em 0.75em 0.75em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 9em;
    font-weight: 600;
  }
}

.hamburger_lines {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5em;
  width: 2.8em;
  height: 2.2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .line,
  .line1,
  .line2,
  .line3 {
    height: 2px;
    width: 100%;
    border-radius: 1em;
    background-color: $color-ffffff;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    background-color: $color-000000;
  }
  // .line1 {
  //   max-width: 75%;
  //   -webkit-transition: 0.25s ease-in-out;
  //   -moz-transition: 0.25s ease-in-out;
  //   -o-transition: 0.25s ease-in-out;
  //   transition: 0.25s ease-in-out;
  // }
  // .line2 {
  //   -webkit-transition: 0.25s ease-in-out;
  //   -moz-transition: 0.25s ease-in-out;
  //   -o-transition: 0.25s ease-in-out;
  //   transition: 0.25s ease-in-out;
  // }
  // .line3 {
  //   max-width: 50%;
  //   -webkit-transition: 0.25s ease-in-out;
  //   -moz-transition: 0.25s ease-in-out;
  //   -o-transition: 0.25s ease-in-out;
  //   transition: 0.25s ease-in-out;
  // }
  &.active {
    .line {
      background-color: $color-000000;
    }
    .line1 {
      max-width: 100%;
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: translateY(1.1em) rotate(135deg);
    }

    .line2 {
      max-width: 100%;
      opacity: 0;
      left: -60px;
    }

    .line3 {
      max-width: 100%;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: translateY(-1em) rotate(-135deg);
    }
  }
  // &.black {
  //   .line {
  //     background-color: $color-000000;
  //   }
  // }
}

@media (max-width: 1536px) {
  .header_sec {
    padding: 1.5em 0;
    .navlinks {
      ul {
        .logo_wrapper {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header_sec {
    // padding: 1.2238em 0;
    padding: 1.7827em 0;
    .logo_wrapper {
      .titax_logo {
        width: 100%;
        max-width: 8.714em;
        // margin-left: -1.5em;
        margin-left: 0;
      }
    }
    .nav_line2_wrapper_flex_wrapper {
      .logo_wrapper {
        top: 1.5em;
        left: 1.5em;
        .titax_logo_1 {
          width: 100%;
          max-width: 8.714em;
        }
      }
    }
  }
}
