@import "../../common.scss";

.faq_sec1 {
  .common_banner_wrapper {
    .breadcrumb_content_wrapper {
      .breadcrumb_text {
        color: $color-1B1B1B;
      }
    }
    .banner_heading {
      color: $color-1C1C1C;
    }
  }
}

.faq_sec2 {
  .faq_container {
    width: 100%;
    .faq_wrap {
      padding: 2.813em 0 2.656em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    
    .faq_wrap.active {
      padding: 2.5em 0 1.563em;
    }
    .faq_title {
      color: $color-1B1B1B;
      font-size: 1.75em;
      font-weight: 600;
      // line-height: 2.625rem;
      line-height: 1.5;
    }
    .faq_title.active {
      color: $color-C2A877;
      letter-spacing: 1.4px;
    }
    .faq_dropdown {
      max-width: 94%;
      -webkit-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.3s ease;
    }
    .faq_p {
      // @include D18180;
      padding-bottom: 2.5em;
      font-size: 1.125em;
      font-weight: 400;
      letter-spacing: 0.9px;
      line-height: 1.945;
    }
    .faq_span {
      // margin-right: 2.5em;
      .minus_icon,
      .plus_icon {
        max-width: 2em;
      }
    }
    .faq_box {
      border-bottom: 1px solid #1c1c1c33;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// .faq_container {
//     .accordion-button::after {
//         display: none;
//     }
//     .accordion-button {
//         justify-content: space-between;
//     }
//     .plus_icon,
//     .minus_icon {
//         width: 100%;
//         max-width: 1.688em;
//     }
//     .accordion-button.collapsed .plus_icon {
//         display: block;
//     }
//     .accordion-button .plus_icon {
//         display: none;
//     }
//     .accordion-button .minus_icon {
//         display: block;
//     }
//     .accordion-button.collapsed .minus_icon {
//         display: none;
//     }
// }

@media (max-width: 767px) {
  .faq_sec2 {
    .faq_container {
      .faq_wrap {
        padding: 1.9em 0;
      }
      .faq_wrap.active {
        padding: 1.7em 0 1.46em;
      }
      .faq_title {
        font-size: 1.8em;
        line-height: 1.688rem;
      }
      .faq_title.active {
        line-height: 1.75rem;
        letter-spacing: 0;
      }
      .faq_p {
        font-size: 1.4em;
        letter-spacing: 0.7px;
        padding-bottom: 1.7em;
        line-height: 1.5;
      }
      .faq_span {
        .plus_icon,
        .minus_icon {
            max-width: 1.367em;
            margin-left: 0.5em;
        }
      }
    }
  }
}
